import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(list: any[],keyword:any): any {
    if(keyword.trim()===''){
      return list;
    }
    return list.filter(item=>{
      return item.designation.toLowerCase().indexOf(keyword.toLowerCase().trim())>=0||item.id.indexOf(keyword.trim())>=0
    })
  }

}

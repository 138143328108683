import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TextService {
  public text_plan:any={
      confirmPlanning:"Saisir un texte",
      supprimePlanning:"Confirmer l'opération ?",
      changePlanning:"Modifier le planning",
      clefClient:"Clef Client : ",
      nombrePlaning:"Nombre de planning total enregistré : ",
      souNombrePlanning:"Désignation du planning par ordre alphabétique",
      or_id:" ID ^",
      or_name:"NAME ^",
      btn_add:"Ajouter",
      btn_del:"Supprimer",
      btn_chan:"Renommer",
      btn_planing:'Planning',
      btn_act:'Actualiser',
      search:"recherche",
      error:"false",
      forbiden_input:';*$',
      alert_nom_exi:'Le nom existe'
      
  };
  public text_modify_planning:any={
    alert:'L\'horaire n\'est pas correct!',
    alert_sauv:'L\'horaire n\'est pas correct pour la PLAGE  ',
    alert_nuit:'Voulez-vous passer la nuit?',
    alert_nuit_sauv:'Voulez-vous passer la nuit pour la PLAGE ',
    alert_op_eff:'Opération effectuée.',
    alert_err:'Il y a une erreur',
    actif:'Actif',
    plage:'TEMPS PLAGE N°',
    debut:'DEBUT',
    fin:'FIN',
    toler_a:'Tolér avant',
    heure:'hh:mm',
    toler_ap:'Tolér après',
    dec:'DECOMPTE',
    pause:'pause',
    lun:'LUN',
    mar:'MAR',
    mer:'MER',
    jeu:'JEU',
    ven:'VEN',
    sam:'SAM',
    dim:'DIM',
    fer:'Férié',
    per:'Personnalisé',
    total:'Temps de travail total:',
    quit:'Quitter',
    recharger:'Recharger',
    sauv:'Sauvegarder',
    verif:'Vérifier',
    
  };

  public login:any = {
    // info_acceuil:'ienvenue sur notre solution,<br>  qui permet d’évaluer le temps<br> de travail de vos employés<br> sur le référentiel  « pointages » <br>du système FESTIME.'
    login_fail:': échec d\'authentification. Recommencez.',
    login_id:'Identifiant',
    login_password:'Mot de Passe',
    login_val:'Valider',
    login_annonce:'',
  }

  public pointer:any = {
    p_d:'Période Du:',
    p_a:'Au:',
    filtre:'Filtre(Nom,Id/No badge): ',
    actu:'Actualiser',
    edition:'Edition',
    ex:'Exporter',
    mo:'Modèle',
    an:'Anomalie',
    in:'Incomplet',
    li:'',
    ok:'OK-hh:mm',
    mq:'MQ-hh:mm',
    dp:'DP-hh:mm',
    ap:'AP-hh:mm',
    e:'Explication',
    e_an:'Erreur Mouvement',
    e_in:'Pointage Partiel',
    e_li:'Libre',
    e_ok:'Juste',
    e_mq:'Manquement',
    e_dp:'Dépassement',
    e_ap:'Planning Absent',
    sys_error:'Erreur système',
    date_error:'Les jours sont pas corrects',
    alert_rapport:'Pour afficher le rapport,\nsi une fenêtre d\'authentification s\'affiche,\nveuillez saisir :\n\nIdentifiant      : rapport\nMot de passe : Motdepasse$1'
  }

  constructor() { }
}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { logging } from 'protractor';
import { DataService } from 'src/app/data.service';

// |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
// Récupération des paramètres de configuration dans le module, selon la position relative du module
//*** Toute modification du fichier assets/config_festiming.json , implique une recompilation du code pour la prise en compte des paramètres ****
import myconfig from "../../../../assets/config_festiming.json";
/*
{
  "VERSION":                "MyTiming 1.2",
  "URL_WEBAPI_SERVER_TEST": "http://siege.abiova.net/bkt.aspx?fonction={fonction}&param1={tsql}",
  "URL_WEBAPI_SERVER_PROD": "http://backend.mytiming.fr/bkt.aspx?fonction={fonction}&param1={tsql}",
  "URL_SERVER_BACKEND_TEST":"http://backend.mytiming.fr",
  "URL_SERVER_BACKEND_PROD":"http://siege.abiova.net",
  "URL_SERVER_FESTIME":     "https://cloud.festime.fr",
  "MODE_EXECUTION":         "PROD",
  "ACTIVER_LIEN_DEBUG":     true  
}
*/
// |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input() login:any;
  @Output() loginChange = new EventEmitter();  

  
  public loggedIn:boolean=false;
  public ACTIVER_LIEN_DEBUG:boolean=false;

  public myconfig = myconfig;

  // variable globale de la Classe : Titre
  title: string;
  
  constructor(private router: Router, private data: DataService ) 
    {

      //console.log(myconfig.URL_SERVER_FESTIME);
      //alert(myconfig.MODE_EXECUTION);
      this.title = myconfig.VERSION;
      this.ACTIVER_LIEN_DEBUG = myconfig.ACTIVER_LIEN_DEBUG;

    }

  // Fonction Appelé à chaque chargement de page ( Reload ou 1ère fois )
  ngOnInit() 
  {   
    //console.log(myconfig.URL_SERVER_FESTIME);
    //alert(myconfig.URL_SERVER_FESTIME);
  }
  public user:string;
  public client_id:string;
  public company:string;

    
// Fonction de Connexion 
SignIn()
{
  
  
  //window.location.reload();
  
  // Recharger la page courante, pour prendre en compte la Déconnexion.
  //location.reload();


  // https://angular.io/guide/router
  // location.href="admin/root"; // revenir au site Racine ( routage défini dans -> app-routing.module.ts )
  
  // location.href="/"; // fonctionne egalement ... page racine
  
  //Méthode Angular 7 pour établir une redirection de routage.
  // l'objet router doit être défini dans le constructeur "constructor" dans ce composant Ts  (TypeScript)
  // this.router.navigate(['/admin','root']); // cela revient à faire l'appel à l'URL -> Monsite/admin/root 
  // C'est bizarre cela ne recharge pas la page ( à revoir )

  localStorage.setItem('loggedIn','true');
 

  // Revenir sur la page racine
 
}

  signOut(){
    
      localStorage.removeItem('loggedIn');
      localStorage.removeItem("login_utilisateur");
      localStorage.removeItem("libelle_utilisateur");
      localStorage.removeItem("cle_axe_client_rattachement");
      localStorage.removeItem("libelle_axe_client");
      localStorage.removeItem("code_profil");
      localStorage.removeItem("code_rh_groupe");
      localStorage.removeItem("actif_utilisateur");
      this.login=0;
      this.loginChange.emit(this.login);
      window.location.reload();
  }

  ngOnChanges(): void {
    this.router.navigate(['/']);
    localStorage.getItem('loggedIn');
     localStorage.getItem("login_utilisateur");
     this.user=  localStorage.getItem("libelle_utilisateur");
     this.client_id=  localStorage.getItem("cle_axe_client_rattachement");
     this.company =  localStorage.getItem("libelle_axe_client");
     localStorage.getItem("code_profil");
     localStorage.getItem("code_rh_groupe");
     localStorage.getItem("actif_utilisateur");
  }
// Fonction de Déconnexion 
onSignOff()
{
  
  
  //window.location.reload();
  
  // Recharger la page courante, pour prendre en compte la Déconnexion.
  //location.reload();


  // https://angular.io/guide/router
  // location.href="admin/root"; // revenir au site Racine ( routage défini dans -> app-routing.module.ts )
  
  // location.href="/"; // fonctionne egalement ... page racine
  
  //Méthode Angular 7 pour établir une redirection de routage.
  // l'objet router doit être défini dans le constructeur "constructor" dans ce composant Ts  (TypeScript)
  // this.router.navigate(['/admin','root']); // cela revient à faire l'appel à l'URL -> Monsite/admin/root 
  // C'est bizarre cela ne recharge pas la page ( à revoir )

  localStorage.setItem('loggedIn','false');
  this.loggedIn = false;

  // Revenir sur la page racine
  this.router.navigate(['/']);
}


  ExternalExecuteUrl(adress:string){
    
    let new_addr = adress;


    // TODO trouver la logique en paramètre externe
    let the_url_backend_server = "";
    let the_url_festime_server = "";

    if ( myconfig.MODE_EXECUTION.toUpperCase() == "PROD" ) // Si PRODUCTION
    {
      the_url_backend_server =  myconfig.URL_SERVER_BACKEND_PROD;  // "http://backend.mytiming.fr";
      the_url_festime_server =  myconfig.URL_SERVER_FESTIME;       //"https://cloud.festime.fr";
    }
    else // SI TEST
    {
      the_url_backend_server =  myconfig.URL_SERVER_BACKEND_TEST;  // "http://siege.abiova.net";
      the_url_festime_server =  myconfig.URL_SERVER_FESTIME;       //"https://cloud.festime.fr";
    }


    new_addr = new_addr.replace("{URL_SERVER_BACKEND}",the_url_backend_server);
    new_addr = new_addr.replace("{URL_SERVER_FESTIME}",the_url_festime_server);
    new_addr = new_addr.replace("{loggedIn}",localStorage.getItem('loggedIn'));
    new_addr = new_addr.replace("{login_utilisateur}",localStorage.getItem('login_utilisateur'));
    new_addr = new_addr.replace("{libelle_utilisateur}",localStorage.getItem('libelle_utilisateur'));
    new_addr = new_addr.replace("{cle_axe_client_rattachement}",localStorage.getItem('cle_axe_client_rattachement'));
    new_addr = new_addr.replace("{libelle_axe_client}",localStorage.getItem('libelle_axe_client'));
    new_addr = new_addr.replace("{code_profil}",localStorage.getItem('code_profil'));
    new_addr = new_addr.replace("{code_rh_groupe}",localStorage.getItem('code_rh_groupe'));
    new_addr = new_addr.replace("{actif_utilisateur}",localStorage.getItem('actif_utilisateur'));

    console.log("new_addr = " + new_addr);

    this.router.navigate(['/extUrl',new_addr]);
  }
  Back(){
    history.back()
  }

}

import { Component, OnInit, OnChanges } from '@angular/core';
import { ActivatedRoute, Params, NavigationEnd, Router } from '@angular/router';
import { DataService } from 'src/app/data.service';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { DownloadService } from 'src/app/services/download.service';

@Component({
  selector: 'app-edition',
  templateUrl: './edition.component.html',
  styleUrls: ['./edition.component.scss']
})
export class EditionComponent implements OnInit {
  public etat_pointage: string;
  private id;
  public uid;
  private date;
  private idCompteClient;
  public name: any;
  public pointages = [];
  public raisons: string[] = [];
  public mask = 0;
  private subscription: Subscription;
  public choice = [];
  public choice_seconde: any[] = [];
  public choice_minute: any[] = [];
  public choice_heure: any[] = [];


  constructor(
    private data: DataService,
    private route: ActivatedRoute,
    private router: Router,
    private download: DownloadService
  ) {

  }

  ngOnInit() {
    this.route.queryParams.subscribe((params: Params) => {
      this.uid = params.id;
      this.date = params.date;
      this.etat_pointage = params.etat_pointage;
      this.idCompteClient = localStorage.getItem("cle_axe_client_rattachement");
      // this.etat_pointage='';
      this.getInfo();

    });
    this.subscription = this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        // this.etat_pointage='';
        this.getInfo()
      })
    for (var i = 0; i < 60; i++) {
      if (i < 10) {
        this.choice_seconde[i] = '0' + i.toString();
      } else {
        this.choice_seconde[i] = i.toString();
      }
    }
    for (var i = 0; i < 60; i++) {
      if (i < 10) {
        this.choice_minute[i] = '0' + i.toString();
      } else {
        this.choice_minute[i] = i.toString();
      }
    }
    for (var i = 0; i < 24; i++) {
      if (i < 10) {
        this.choice_heure[i] = '0' + i.toString();
      } else {
        this.choice_heure[i] = i.toString();
      }
    }
    // console.log(this.choice_seconde);
  }

  getInfo() {
    this.pointages = [];
    let tsql = "exec sp_festiming_extraire_pointage_personne @cle_uid_personne='{cle_uid_personne}' , @date_base_pointage='{date_base_pointage}' , @cle_axe_client_rattachement={idCompteClient}"
    tsql = tsql.replace("{cle_uid_personne}", this.uid);
    tsql = tsql.replace("{date_base_pointage}", this.date);
    tsql = tsql.replace("{idCompteClient}", this.idCompteClient);
    this.mask = 1
    this.data.SqlSelectToJson(tsql).subscribe
      (
        (data: any) => {
          this.pointages = data;
          console.log("back-end telecharge:");
          console.log(data);

        },
        (error) => {
          console.log("__________Observable SqlSubmit est en Erreur : " + error);
          alert(error);
        },
        () => {
          for (var i = 0; i < this.pointages.length; i++) {
            this.pointages[i].jour = this.formatNum(this.pointages[i].jour);
            this.pointages[i].mois = this.formatNum(this.pointages[i].mois);
            this.pointages[i].heure = this.formatNum(this.pointages[i].heure);
            this.pointages[i].minute = this.formatNum(this.pointages[i].minute);
            this.pointages[i].seconde = this.formatNum(this.pointages[i].seconde);
            this.name = this.pointages[0]['nom-prenom'];
            this.choice[i] = 0;
          }
          var raison = this.pointages[0].choix_liste_pauses;
          this.raisons = raison.split(';');
          this.mask = 0;

        }
      );
  }

  add() {
    
    // var newarr:any[]=[];
    // newarr = JSON.parse(JSON.stringify(this.pointages));
    // var length = newarr.length
    // var new_pointage = newarr[length-1];
    // new_pointage.statut=localStorage.getItem('login_utilisateur');
    // new_pointage.id_pointage = '0';
    // this.pointages.push(new_pointage)
    // console.log(this.pointages)
    // for(var i=0;i<this.pointages.length;i++){
    //   this.choice[i]=0;
    // }

    var new_pointage= JSON.parse(JSON.stringify(this.pointages[0])) ;
    new_pointage.statut = localStorage.getItem('login_utilisateur');
    new_pointage.id_pointage = '0';

    
    if(this.pointages.length>1||this.pointages[0].ReturnCode!=="KO"){

      for (var i = 0; i < this.choice.length; i++) {
        if (this.choice[i]) {
  
          var newarr: any[] = [];
          // newarr = this.pointages;
          newarr = JSON.parse(JSON.stringify(this.pointages));
          
          new_pointage = newarr[i];
          new_pointage.statut = localStorage.getItem('login_utilisateur');
          new_pointage.id_pointage = '0';
  
          this.pointages.splice(i+1,0,new_pointage);
          for (var i = 0; i < this.pointages.length; i++) {
            this.choice[i] = 0;
          }
          return;
        }
      }
    }else{
      new_pointage.ReturnCode ="OK";
      new_pointage.mouvement = "A";
      this.pointages.push(new_pointage);
    } 
    for (var i = 0; i < this.pointages.length; i++) {
      this.choice[i] = 0;
    }

    // console.log("add apres:");
    // console.log(this.pointages);
  }

  delete() {
    console.log(this.choice);
    for (var i = 0; i < this.choice.length; i++) {
      if (this.choice[i]) {
        // this.pointages.splice(i,1);
        // this.choice.splice(i,1);
        // i = i-1;
        this.pointages[i].id_pointage = "D_" + this.pointages[i].id_pointage;
      }
    }
    console.log(this.pointages);
    this.choice = [];
    for (var i = 0; i < this.pointages.length; i++) {
      this.choice[i] = 0;
    }
    // console.log(this.choice)
  }



  formatNum(num) {
    if (parseInt(num) < 10) {
      num = '0' + parseInt(num);
      return num;
    } else {
      return num;
    }
  }

  ordre() {
    for (var i = 0; i < this.pointages.length; i++) {
      for (var j = i + 1; j < this.pointages.length; j++) {
        if (parseInt(this.pointages[i].annee) > parseInt(this.pointages[j].annee)) {
          var change = this.pointages[i];
          this.pointages[i] = this.pointages[j];
          this.pointages[j] = change;
        } else if (parseInt(this.pointages[i].annee) == parseInt(this.pointages[j].annee) && parseInt(this.pointages[i].mois) > parseInt(this.pointages[j].mois)) {
          var change = this.pointages[i];
          this.pointages[i] = this.pointages[j];
          this.pointages[j] = change;
        } else if (parseInt(this.pointages[i].annee) == parseInt(this.pointages[j].annee) && parseInt(this.pointages[i].mois) == parseInt(this.pointages[j].mois) && parseInt(this.pointages[i].jour) > parseInt(this.pointages[j].jour)) {
          var change = this.pointages[i];
          this.pointages[i] = this.pointages[j];
          this.pointages[j] = change;
        } else if (parseInt(this.pointages[i].annee) == parseInt(this.pointages[j].annee) && parseInt(this.pointages[i].mois) == parseInt(this.pointages[j].mois) && parseInt(this.pointages[i].jour) == parseInt(this.pointages[j].jour) && parseInt(this.pointages[i].heure) > parseInt(this.pointages[j].heure)) {
          var change = this.pointages[i];
          this.pointages[i] = this.pointages[j];
          this.pointages[j] = change;
        } else if (parseInt(this.pointages[i].annee) == parseInt(this.pointages[j].annee) && parseInt(this.pointages[i].mois) == parseInt(this.pointages[j].mois) && parseInt(this.pointages[i].jour) == parseInt(this.pointages[j].jour) && parseInt(this.pointages[i].heure) == parseInt(this.pointages[j].heure) && parseInt(this.pointages[i].minute) > parseInt(this.pointages[j].minute)) {
          var change = this.pointages[i];
          this.pointages[i] = this.pointages[j];
          this.pointages[j] = change;
        } else if (parseInt(this.pointages[i].annee) == parseInt(this.pointages[j].annee) && parseInt(this.pointages[i].mois) == parseInt(this.pointages[j].mois) && parseInt(this.pointages[i].jour) == parseInt(this.pointages[j].jour) && parseInt(this.pointages[i].heure) == parseInt(this.pointages[j].heure) && parseInt(this.pointages[i].minute) == parseInt(this.pointages[j].minute) && parseInt(this.pointages[i].seconde) > parseInt(this.pointages[j].seconde)) {
          var change = this.pointages[i];
          this.pointages[i] = this.pointages[j];
          this.pointages[j] = change;
        }
      }
    }
    console.log("ordre apres:");
    console.log(this.pointages);
  }

  raison(p) {
    if (p.mouvement != 'P') {
      p.codepause = '';
      p.textepause = '';
    }
  }

  save(callback?) {
    this.ordre();



    var sauv_pointages: any[] = [];
    var new_statue = localStorage.getItem('login_utilisateur');
    for (var i = 0; i < this.pointages.length; i++) {
      this.pointages[i].statut = new_statue;
      // console.log(this.pointages[i].statut);
      sauv_pointages[i] = JSON.stringify(this.pointages[i]);
    }
    console.log("avant backend-sauv:");
    console.log(sauv_pointages);
    let tsql = "exec sp_festiming_sauvegarder_pointage_personne @cle_uid_personne='{cle_uid_personne}' , @date_base_pointage='{date_base_pointage}' , @cle_axe_client_rattachement={idCompteClient}, @matrice_update_json='[{matrice_update_json}]'";
    tsql = tsql.replace("{cle_uid_personne}", this.uid);
    tsql = tsql.replace("{date_base_pointage}", this.date);
    tsql = tsql.replace("{idCompteClient}", this.idCompteClient);
    // console.log(sauv_pointages.toString()) 
    //tsql = tsql.replace("{matrice_update_json}", sauv_pointages.toString());
    tsql = tsql.replace("{matrice_update_json}", sauv_pointages.toString().replace(/'/g,"''"));
    // console.log(tsql);
    // alert(tsql);

    this.data.SqlSelectToJson(tsql).subscribe
      (

        (data: any) => {
          // console.log(data);
          this.etat_pointage = data[0].bilan_apres_sauvegarde;
          console.log(this.etat_pointage);

        },
        (error) => {
          console.log("__________Observable SqlSubmit est en Erreur : " + error);
        },

        () => {
          this.getInfo();
          callback();
        }
      )

  }

  codepause(pointage) {
    pointage.codepause = pointage.textepause.substring(0, 1);
    console.log(pointage.codepause);
  }


  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.subscription.unsubscribe();
  }

  downLoad() {
    this.ordre();
    var file_name = localStorage.getItem("login_utilisateur");
    var confirm = window.confirm('Il faut sauvgarder d\'abord dans le cas ou vous avez modifié des pointage(s)... Confirmer ?');
    if (confirm) {
      // this.save();
      var pointages: any[] = [];
      for (var i = 0; i < this.pointages.length; i++) {
        if (this.pointages[i].id_pointage.substring(0, 2) != 'D_') {
          pointages.push(
            {
              id_pointage: '',
              no_site: '',
              code_groupe: '',
              uid: '',
              'nom-prenom': '',
              base_jour: '',
              statut: '',
              mouvement: '',
              textepause: '',
              nomjour: '',
              jour: '',
              mois: '',
              annee: '',
              heure: '',
              minute: '',
              seconde: '',
              etat_pointage: '',
            }
          )
          pointages[i].id_pointage = this.pointages[i].id_pointage;
          pointages[i].no_site = this.pointages[i].code_site;
          pointages[i].code_groupe = this.pointages[i].code_groupe;
          pointages[i].uid = this.pointages[i].uid;
          pointages[i]['nom-prenom'] = this.pointages[i]['nom-prenom'];
          pointages[i].base_jour = this.pointages[i].base_jour;
          pointages[i].statut = this.pointages[i].statut;
          pointages[i].mouvement = this.pointages[i].mouvement;
          pointages[i].textepause = this.pointages[i].textepause;
          pointages[i].nomjour = this.pointages[i].nomjour;
          pointages[i].jour = this.pointages[i].jour;
          pointages[i].mois = this.pointages[i].mois;
          pointages[i].annee = this.pointages[i].annee;
          pointages[i].heure = this.pointages[i].heure;
          pointages[i].minute = this.pointages[i].minute;
          pointages[i].seconde = this.pointages[i].seconde;
          // pointages[i].base_jour = this.pointages[i].base_jour;
          pointages[i].etat_pointage = this.etat_pointage;
        }
      }
      var arr_colonie: string[] = Object.keys(pointages[0]);
      this.download.downloadFile(pointages, file_name + '-' + this.date + '-' + pointages[0]['nom-prenom'], arr_colonie);

    } else {
      return;
    }







  }

}

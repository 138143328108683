import { Component, OnInit } from '@angular/core';
import { AppData } from 'src/app/models/AppData.model';
import { LoginData } from 'src/app/models/LoginData.model';
import { DataService } from 'src/app/data.service';
import { stringify } from '@angular/core/src/render3/util';

@Component({
  selector: 'app-gethttptest',
  templateUrl: './gethttptest.component.html',
  styleUrls: ['./gethttptest.component.css']
})
export class GethttptestComponent implements OnInit {

  mydata: Object;
  zdata = new AppData();
  zlogin = new LoginData();

  
  constructor(private data: DataService)
   { 
    // this.ngOnInit();
   }


  ngOnInit() 
  {
    this.initForm();   
  }

  initForm()
  {
   this.Effacer();

   // Récupération de la donnée de Cnx du localStorage ( en réalité , à l'instanciation de l'objet zlogin, l'opération de Maj est automatiquement effectuée)
    this.zlogin.cle_axe_client_rattachement = localStorage.getItem("cle_axe_client_rattachement");
  }


  ExecuterAction(the_action:string , type_exec:string)
  {
    
    // alert("Run Execution 1.... : " + the_action);

    switch (type_exec) 
     {
       
        case "process":
          this.SqlSubmit(the_action);         
        break;
     
        
         case "json":
            this.SqlSelectToJson(the_action);         
         break;

        case "csv":
          this.SqlSelectToCsv(the_action);         
        break;


       default:
         break;
     }
  }


  Effacer()
  {
    this.zdata.tsql="?";
    this.zdata.fluxretour="";
    this.zdata.coderet ="";
    this.zdata.textret = "";
    this.zdata.dataret = "";
  }



  SqlSubmit(tsql:string)
  {
            
             this.data.SqlSubmit(tsql).subscribe // création d'un observateur dynamique sur l'observable -> data.SqlSubmit(..)
             (
                // OBSERVATEUR DEBUT .......................................................................................................
                            // Evenement de Fin de d'exécution correcte en provenance de l'observable
                            (data) =>       {
                                              console.log("__________Observable SqlSubmit a renvoyé une Fin de d'exécution correcte.");
                                              this.mydata = data;
                                              console.log(this.mydata);
                                              
                                              // TODO simuler l'exéctution d'une requête au serveur Http (grosse recherche ANGULAR)
                                              // simulation de réponse :
                                              this.zdata.fluxretour = this.mydata.toString();
                                              
                                              // Extraction du Code retour Serveur sur l'opération SqlSubmit ( extraction retour sur 3 zones du flux de retour )
                                              let zones = this.mydata.toString().split(';');
                                              this.zdata.coderet = zones[0];
                                              this.zdata.textret = zones[1];
                                              this.zdata.dataret = zones[2];                                  

                                          },

                              // Evenement d'erreur d'exécution en provenance de l'observable
                            (error) =>       {
                                              console.log("__________Observable SqlSubmit a renvoyé une erreur : "+ error.message);
                                              this.zdata.coderet = "KO";
                                              this.zdata.textret = error;
                                              this.zdata.dataret = "Erreur système côté FrontEnd."; 

                                              alert(error.message);
                                           },
                                        
                             // Evenement de fin d'exécution en provenance de l'observable quand exécution normal!  bloc de codes (data) exécuté avant, puis blocs de code ()
                            () =>         {
                                            // on passe uniquement ici après que l'exécution soit normale , donc événement (data)
                                            console.log("__________Observable SqlSubmit a renvoyé un événement de fin d'exécution.");
                                          } 
               // OBSERVATEUR FIN .......................................................................................................

            );
  
  }




  SqlSelectToJson(tsql:string)
  {
            
             this.data.SqlSelectToJson(tsql).subscribe // création d'un observateur dynamique sur l'observable -> data.SqlSelectToJson(..)
             (
                // OBSERVATEUR DEBUT .......................................................................................................
                            // Evenement de Fin de d'exécution correcte en provenance de l'observable
                            (data) =>       {
                                              console.log("__________Observable SqlSelectToJson a renvoyé une Fin de d'exécution correcte.");
                                              this.mydata = data;
                                              console.log(this.mydata);
                                              
                                              // TODO simuler l'exéctution d'une requête au serveur Http (grosse recherche ANGULAR)
                                              // simulation de réponse :
                                              this.zdata.fluxretour = this.mydata.toString();
                                              this.zdata.coderet = "OK";
                                              this.zdata.textret = "";
                                              this.zdata.dataret = "";                                  

                                          },

                              // Evenement d'erreur d'exécution en provenance de l'observable
                            (error) =>       {
                                              console.log("__________Observable SqlSelectToJson a renvoyé une erreur : "+ error.message);
                                              this.zdata.coderet = "KO";
                                              this.zdata.textret = error;
                                              this.zdata.dataret = ""; 
                                              alert(error.message);
                                           },
                                        
                             // Evenement de fin d'exécution en provenance de l'observable quand exécution normal!  bloc de codes (data) exécuté avant, puis blocs de code ()
                            () =>         {
                                            // on passe uniquement ici après que l'exécution soit normale , donc événement (data)
                                            console.log("__________Observable SqlSelectToJson a renvoyé un événement de fin d'exécution.");
                                          } 
               // OBSERVATEUR FIN .......................................................................................................

            );
  
  }




  SqlSelectToCsv(tsql:string)
  {
            
             this.data.SqlSelectToCsv(tsql).subscribe // création d'un observateur dynamique sur l'observable -> data.SqlSelectToCsv(..)
             (
                // OBSERVATEUR DEBUT .......................................................................................................
                            // Evenement de Fin de d'exécution correcte en provenance de l'observable
                            (data) =>       {
                                              console.log("__________Observable SqlSelectToCsv a renvoyé une Fin de d'exécution correcte.");
                                              this.mydata = data;
                                              console.log(this.mydata);
                                              
                                              // TODO simuler l'exéctution d'une requête au serveur Http (grosse recherche ANGULAR)
                                              // simulation de réponse :
                                              this.zdata.fluxretour = this.mydata.toString();
                                              this.zdata.coderet = "OK";
                                              this.zdata.textret = "";
                                              this.zdata.dataret = "";                                  

                                          },

                              // Evenement d'erreur d'exécution en provenance de l'observable
                            (error) =>       {
                                              console.log("__________Observable SqlSelectToCsv a renvoyé une erreur : "+ error.message);
                                              this.zdata.coderet = "KO";
                                              this.zdata.textret = error;
                                              this.zdata.dataret = ""; 
                                              alert(error.message);
                                           },
                                        
                             // Evenement de fin d'exécution en provenance de l'observable quand exécution normal!  bloc de codes (data) exécuté avant, puis blocs de code ()
                            () =>         {
                                            // on passe uniquement ici après que l'exécution soit normale , donc événement (data)
                                            console.log("__________Observable SqlSelectToCsv a renvoyé un événement de fin d'exécution.");
                                          } 
               // OBSERVATEUR FIN .......................................................................................................

            );
  
  }




}

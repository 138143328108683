import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DataService } from 'src/app/data.service';
import { allocExpando } from '@angular/core/src/render3/instructions';
import { PopupService } from 'src/app/services/popup.service';
import { TextService } from 'src/app/services/text.service';
import { Router, NavigationExtras} from '@angular/router';
import { DownloadService } from 'src/app/services/download.service';



@Component({
  selector: 'app-pointer',
  templateUrl: './pointer.component.html',
  styleUrls: ['./pointer.component.scss']
})
export class PointerComponent implements OnInit {


  @ViewChild('result') resultImage:ElementRef;


  public days:any[]=['LUN','MAR','MER','JEU','VEN','SAM','DIM'];
  public ret:any[]=[];
  public today:Date = new Date();
  public year = this.today.getFullYear();
  public month = this.today.getMonth() + 1;
  public affiche_month;
  public date = this.today.getDate();
  public idCompteClient; 
  public pre_choice_day='';
  public nex_choice_day='';
  public filtre=''
  public open_pre:boolean=false;
  public open_nex:boolean=false;
  public workers:any[];
  public jours:string[]=[''];
  public width="1500px";
  public height="370px";
  public choice_day:any[][]=[];
  public mask = 0;
  public popup:number[]=new Array();
  public funcs:string='';
  public _params:any = 0;
  

  constructor(
    private data: DataService,
    private popupService:PopupService,
    public text:TextService,
    private router:Router,
    private download:DownloadService
    ) {
    
  }

  ngOnInit() {   
      if(this.month<10){
        this.affiche_month='0'+this.month;
      }else{
        this.affiche_month=this.month
      }
      this.getMonthDate(this.year, this.month);

      // var image:any = document.getElementById('resultImage');
      // const image_:CanvasRenderingContext2D = this.resultImage.nativeElement.getContext('2d');
      // console.log(image_)
      // image_.strokeStyle = '#ff0000';
      // image_.fillStyle='orange';
     
      // image_.beginPath();
      // image_.moveTo(50,50)
      // image_.arc(50,50,40,0,40*Math.PI/180,true);
      // image_.fill();
      // image_.stroke();


    
    // ......................................................................
    // correctif (MAA) ...Initialiser au 1er chargement la page [Controler] à semaine encours
    this.funcs="week";
    //.......................................................................  

    this.specialFunctions(this.funcs);
    this.getInfo(this.pre_choice_day, this.nex_choice_day,'');


  }


  Reporting(uid){
    var client_rattachement = localStorage.getItem("cle_axe_client_rattachement");
    let url = this.data.GetUrlReporting(client_rattachement,uid,this.pre_choice_day,this.nex_choice_day);
    // this.router.navigate(['extUrl',url]);
    alert(this.text.pointer.alert_rapport);
    // this.popupService.alertOpen('dsdsadsafds')
    var myWindow = window.open(url,"_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=50,left=50,width=800,height=900,menubar=no,toolbar=no,status=no");
    // var iframe =  "<iframe id=\"inlineFrameExample\"title=\'Inline Frame Example\'width=\'300\'height=\'200\'src="+url+"</iframe>";
    // myWindow.document.write(iframe);
    // myWindow.document.write('<p>test</p>');
  }
  


  preMonth(){
    
    if(this.month==1){
      this.year=this.year-1;
      this.month=13
    }
    this.month=this.month-1;
    this.getMonthDate(this.year,this.month)
    if(this.month<10){
      this.affiche_month='0'+this.month;
    }else{
      this.affiche_month=this.month
    }
  }
  nexMonth(){
    if(this.month==12){
      this.year=this.year+1;
      this.month=0;
    }
    this.month=this.month+1;
    this.getMonthDate(this.year,this.month)
    if(this.month<10){
      this.affiche_month='0'+this.month;
    }else{
      this.affiche_month=this.month
    }
  }
  preChoice(date){
    this.pre_choice_day=this.formatDay(date.date,date.month,date.year);
   
  }
  nexChoice(date){
   this.nex_choice_day=this.formatDay(date.date,date.month,date.year);
  }


  research(){
   this.getInfo(this.pre_choice_day,this.nex_choice_day,this.filtre);
   this.router.navigate(['/pointer']);
  }

  background(color){
    if(color.indexOf('OK')!=-1) return 'green';
    if(color.indexOf('DP')!=-1) return 'rgb(136, 89, 15)';
    if(color.indexOf('MQ')!=-1) return 'red';
    if(color.indexOf('Incomplet')!=-1) return 'rgba(105, 100, 89, 0.952)';
    if(color.indexOf('Anomalie')!=-1) return 'rgba(105, 100, 89, 0.952)';
    if(color.indexOf('AP')!=-1) return 'rgb(19, 32, 153)';
    
  }

  specialFunctions(result){
  
      var month = this.today.getMonth()+1;
      var date = this.today.getDate();
      var year = this.today.getFullYear();
      var day = this.today.getDay();
    

      //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      //************************************************************************ (MAA) debut week ********************
      //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      if(result=='week')
      {
                
        if(day==0) // (MAA) Si Dimanche
        {
        n_date = date;
        n_month = month;
        n_year = year;

        // alert('date:'+date);
        // alert('month:'+month);
        // alert('year:'+year);


        this.nex_choice_day=this.formatDay(date,month,year);
        //alert('this.nex_choice_day:'+this.nex_choice_day);

        p_date = n_date - 6;
        if(p_date<=0) // (MAA) Si changement de Mois détecté 6 jours avant
          {
              p_month = n_month-1;
                // console.log(p_month)
                if(p_month==0)
                {
                  p_month = 12;
                  var lastDayLastMonth = new Date(n_year,0,0);
                  p_year = n_year-1;
                }
                else
                {
                  p_year = n_year;
                  var lastDayLastMonth = new Date(p_year,p_month,0);
                }
            
            p_date=p_date+lastDayLastMonth.getDate();
            this.pre_choice_day=this.formatDay(p_date,p_month,p_year);
            
            // (MAA)
            //result.target.value='week';

          }
          else // (MAA) Jours calculé du début de semaine est toujours sur le même mois en cours.
          {
            
           // (MAA) Même mois et même année :
            var p_month = month; // (MAA) correctif
            var p_year = year; // (MAA) correctif

            this.pre_choice_day=this.formatDay(p_date,p_month,p_year);
            //alert('pre_choice_day:'+this.pre_choice_day);

            // MAA
            //result.target.value='week';

            // Comment forcer l'affichage par défaut sur une valeur de manière Dynamique
            //result.target.value = 'week';
            
          }    
      }
      
      else // (MAA) Si jour <> de Dimanche ( (day !=0 ))
      {
                
        var n_date = (7-day)+date;
        var n_month =month;
        var n_year = year;

        // alert('month:'+month);
        // alert('year:'+year);


        if(n_month==12){
          var lastDate = new Date(n_year+1,0,0);
        }else{
          var lastDate = new Date(n_year,n_month,0);
        }
        if(n_date>lastDate.getDate()){
          n_date = n_date-lastDate.getDate();
          if(n_month==12){
            n_month=1;
            n_year=n_year+1;
          }else{
            n_month=n_month+1
          }
          this.nex_choice_day=this.formatDay(n_date,n_month,n_year);
        }else{
          this.nex_choice_day=this.formatDay(n_date,n_month,n_year);
        }        
        var p_date = n_date-6;
        var p_year = n_year;
        var p_month = n_month;
        if(p_date<=0){
          p_month=p_month-1;
          if(p_month==0){
            p_month = 12;
            var lastDayLastMonth = new Date(p_year,0,0);
            p_year = p_year-1;
          }else{
            var lastDayLastMonth = new Date(p_year,p_month,0);
          }
          p_date=p_date+lastDayLastMonth.getDate();
          this.pre_choice_day=this.formatDay(p_date,p_month,p_year);
        //  result.target.value='';
        }else{
          this.pre_choice_day=this.formatDay(p_date,p_month,p_year);
        //  result.target.value='';
        }    
      }
      this.research();
    }
    //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    //************************************************************************ fin week ****************************
    //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~


    else if(result=='lastWeek'){
      
      
      if(day==0){
        n_date = date-7;
        n_month = month;
        n_year = year;
        if(n_date<=0){
          n_month=n_month-1;
          if(n_month==0){
            n_month=12;
            var lastDayLastMonth = new Date(n_year,0,0);
            n_year = n_year-1;
          }else{
            var lastDayLastMonth = new Date(n_year,n_month,0);
          }
          n_date=n_date+lastDayLastMonth.getDate();
          this.nex_choice_day=this.formatDay(n_date,n_month,n_year);
        //  result.target.value='';
        }else{
          this.nex_choice_day=this.formatDay(n_date,n_month,n_year);
         // result.target.value='';
        } 
        var p_date = n_date-6;
        var p_month =n_month;
        var p_year = n_year;
        if(p_date<=0){
          p_month=p_month-1;
          if(p_month==0){
            p_month=12;
            var lastDayLastMonth = new Date(p_year,0,0);
            p_year = p_year-1;
          }else{

            var lastDayLastMonth = new Date(p_year,p_month,0);
          }
          p_date=p_date+lastDayLastMonth.getDate();
          this.pre_choice_day=this.formatDay(p_date,p_month,p_year);
        //  result.target.value='';
        }else{
          this.pre_choice_day=this.formatDay(p_date,p_month,p_year);
        //  result.target.value='';
        } 
      }else if(day!=0){
        
        
        var n_date = (7-day)+date-7;
        var n_month =month;
        var n_year = year;
        if(n_date<=0){
          n_month=n_month-1;
          if(n_month==0){
            n_month=12;
            var lastDayLastMonth = new Date(n_year,0,0);
            n_year = n_year-1;
          }else{
            var lastDayLastMonth = new Date(n_year,n_month,0);
          }
          n_date=n_date+lastDayLastMonth.getDate();
          this.nex_choice_day=this.formatDay(n_date,n_month,n_year);
         // result.target.value='';
        }else{
          this.nex_choice_day=this.formatDay(n_date,n_month,n_year);
        //  result.target.value='';
        } 
        var p_date = n_date-6;
        var p_month =n_month;
        var p_year = n_year;
        if(p_date<=0){
          p_month=p_month-1;
          if(p_month==0){
            p_month=12;
            var lastDayLastMonth = new Date(p_year,0,0);
            p_year = p_year-1;
          }else{
            var lastDayLastMonth = new Date(p_year,p_month,0);
          }
          p_date=p_date+lastDayLastMonth.getDate();
          

          this.pre_choice_day=this.formatDay(p_date,p_month,p_year);
         // result.target.value='';
        }else{
          this.pre_choice_day=this.formatDay(p_date,p_month,p_year);
         // result.target.value='';
        }    
      }
      this.research();
    }else if(result=='lastWeek_2'){
      
      if(day==0){
        n_date = date-14;
        n_month = month;
        n_year = year;
        if(n_date<=0){
          n_month=n_month-1;
          if(n_month==0){
            n_month=12;
            var lastDayLastMonth = new Date(n_year,0,0);
            n_year = n_year-1;
          }else{
            var lastDayLastMonth = new Date(n_year,n_month,0);
          }
          n_date=n_date+lastDayLastMonth.getDate();
          this.nex_choice_day=this.formatDay(n_date,n_month,n_year);
        //  result.target.value='';
        }else{
          this.nex_choice_day=this.formatDay(n_date,n_month,n_year);
         // result.target.value='';
        } 
        var p_date = n_date-6;
        var p_month =n_month;
        var p_year = n_year;
        if(p_date<=0){
          p_month=p_month-1;
          if(p_month==0){
            p_month=12;
            var lastDayLastMonth = new Date(p_year,0,0);
            p_year = p_year-1;
          }else{
            var lastDayLastMonth = new Date(p_year,p_month,0);
          }
          p_date=p_date+lastDayLastMonth.getDate();
          this.pre_choice_day=this.formatDay(p_date,p_month,p_year);
        //  result.target.value='';
        }else{
          this.pre_choice_day=this.formatDay(p_date,p_month,p_year);
        //  result.target.value='';
        } 
      }else if(day!=0){
      
        var n_date = (7-day)+date-14;
        var n_month =month;
        var n_year = year;
        if(n_date<=0){
          n_month=n_month-1;
          if(n_month==0){
            n_month=12;
            var lastDayLastMonth = new Date(n_year,0,0);
            n_year = n_year-1;
          }else{
            var lastDayLastMonth = new Date(n_year,n_month,0);
          }
          n_date=n_date+lastDayLastMonth.getDate();
          this.nex_choice_day=this.formatDay(n_date,n_month,n_year);
         // result.target.value='';
        }else{
          this.nex_choice_day=this.formatDay(n_date,n_month,n_year);
        //  result.target.value='';
        } 
        var p_date = n_date-6;
        var p_month =n_month;
        var p_year = n_year;
        if(p_date<=0){
          p_month=p_month-1;
          if(p_month==0){
            p_month=12;
            var lastDayLastMonth = new Date(p_year,0,0);
            p_year = p_year-1;
          }else{
            var lastDayLastMonth = new Date(p_year,p_month,0);
          }
          p_date=p_date+lastDayLastMonth.getDate();
          

          this.pre_choice_day=this.formatDay(p_date,p_month,p_year);
         // result.target.value='';
        }else{
          this.pre_choice_day=this.formatDay(p_date,p_month,p_year);
         // result.target.value='';
        }  
      }    
      this.research();
    }else if(result=='lastWeek_3'){
      if(day==0){
        n_date = date-21;
        n_month = month;
        n_year = year;
        if(n_date<=0){
          n_month=n_month-1;
          if(n_month==0){
            n_month=12;
            var lastDayLastMonth = new Date(n_year,0,0);
            n_year = n_year-1;
          }else{
            var lastDayLastMonth = new Date(n_year,n_month,0);
          }
          n_date=n_date+lastDayLastMonth.getDate();
          this.nex_choice_day=this.formatDay(n_date,n_month,n_year);
        //  result.target.value='';
        }else{
          this.nex_choice_day=this.formatDay(n_date,n_month,n_year);
         // result.target.value='';
        } 
        var p_date = n_date-6;
        var p_month =n_month;
        var p_year = n_year;
        if(p_date<=0){
          p_month=p_month-1;
          if(p_month==0){
            p_month=12;
            var lastDayLastMonth = new Date(p_year,0,0);
            p_year = p_year-1;
          }else{
            var lastDayLastMonth = new Date(p_year,p_month,0);
          }
          p_date=p_date+lastDayLastMonth.getDate();
          this.pre_choice_day=this.formatDay(p_date,p_month,p_year);
        //  result.target.value='';
        }else{
          this.pre_choice_day=this.formatDay(p_date,p_month,p_year);
        //  result.target.value='';
        } 
      }else if(day!=0){
        
        
        var n_date = (7-day)+date-21;
        var n_month =month;
        var n_year = year;
        if(n_date<=0){
          n_month=n_month-1;
          if(n_month==0){
            n_month=12;
            var lastDayLastMonth = new Date(n_year,0,0);
            n_year = n_year-1;
          }else{
            var lastDayLastMonth = new Date(n_year,n_month,0);
          }
          n_date=n_date+lastDayLastMonth.getDate();
          this.nex_choice_day=this.formatDay(n_date,n_month,n_year);
         // result.target.value='';
        }else{
          this.nex_choice_day=this.formatDay(n_date,n_month,n_year);
        //  result.target.value='';
        } 
        var p_date = n_date-6;
        var p_month =n_month;
        var p_year = n_year;
        if(p_date<=0){
          p_month=p_month-1;
          if(p_month==0){
            p_month=12;
            var lastDayLastMonth = new Date(p_year,0,0);
            p_year = p_year-1;
          }else{
            var lastDayLastMonth = new Date(p_year,p_month,0);
          }
          p_date=p_date+lastDayLastMonth.getDate();
          

          this.pre_choice_day=this.formatDay(p_date,p_month,p_year);
         // result.target.value='';
        }else{
          this.pre_choice_day=this.formatDay(p_date,p_month,p_year);
         // result.target.value='';
        }  
      }  
      this.research();
    }else if(result=='lastWeek_4'){
      if(day==0){
        n_date = date-28;
        n_month = month;
        n_year = year;
        if(n_date<=0){
          n_month=n_month-1;
          if(n_month==0){
            n_month=12;
            var lastDayLastMonth = new Date(n_year,0,0);
            n_year = n_year-1;
          }else{
            var lastDayLastMonth = new Date(n_year,n_month,0);
          }
          n_date=n_date+lastDayLastMonth.getDate();
          this.nex_choice_day=this.formatDay(n_date,n_month,n_year);
        //  result.target.value='';
        }else{
          this.nex_choice_day=this.formatDay(n_date,n_month,n_year);
         // result.target.value='';
        } 
        var p_date = n_date-6;
        var p_month =n_month;
        var p_year = n_year;
        if(p_date<=0){
          p_month=p_month-1;
          if(p_month==0){
            p_month=12;
            var lastDayLastMonth = new Date(p_year,0,0);
            p_year = p_year-1;
          }else{
            var lastDayLastMonth = new Date(p_year,p_month,0);
          }
          p_date=p_date+lastDayLastMonth.getDate();
          this.pre_choice_day=this.formatDay(p_date,p_month,p_year);
        //  result.target.value='';
        }else{
          this.pre_choice_day=this.formatDay(p_date,p_month,p_year);
        //  result.target.value='';
        } 
      }else if(day!=0){
      
        var n_date = (7-day)+date-28;
        var n_month =month;
        var n_year = year;
        if(n_date<=0){
          n_month=n_month-1;
          if(n_month==0){
            n_month=12;
            var lastDayLastMonth = new Date(n_year,0,0);
            n_year = n_year-1;
          }else{
            var lastDayLastMonth = new Date(n_year,n_month,0);
          }
          n_date=n_date+lastDayLastMonth.getDate();
          this.nex_choice_day=this.formatDay(n_date,n_month,n_year);
         // result.target.value='';
        }else{
          this.nex_choice_day=this.formatDay(n_date,n_month,n_year);
        //  result.target.value='';
        } 
        var p_date = n_date-6;
        var p_month =n_month;
        var p_year = n_year;
        if(p_date<=0){
          p_month=p_month-1;
          if(p_month==0){
            p_month=12;
            var lastDayLastMonth = new Date(p_year,0,0);
            p_year = p_year-1;
          }else{
            var lastDayLastMonth = new Date(p_year,p_month,0);
          }
          var lastDayLastMonth = new Date(p_year,p_month,0);
          p_date=p_date+lastDayLastMonth.getDate();
          

          this.pre_choice_day=this.formatDay(p_date,p_month,p_year);
         // result.target.value='';
        }else{
          this.pre_choice_day=this.formatDay(p_date,p_month,p_year);
         // result.target.value='';
        }  
      }
      this.research();
    }else if(result=='month'){
      
      var lastDay = new Date(year,month,0);
      this.nex_choice_day=this.formatDay(lastDay.getDate(),month,year);
      this.pre_choice_day='01'+this.nex_choice_day.substr(2);
      //result.target.value='';
      this.research();
    }else if(result=='lastMonth'){
      month = month - 1;
      if(month==0){
        month = 12;
        var lastDay = new Date(year,0,0);
        this.nex_choice_day=this.formatDay(lastDay.getDate(),month,year-1);
      }else{
        var lastDay = new Date(year,month,0)
        this.nex_choice_day=this.formatDay(lastDay.getDate(),month,year);
      }
      this.pre_choice_day='01'+this.nex_choice_day.substr(2);
      this.research();
      
    }else if(result==''){
      return;
    }
    // alert(choice);
    // this.pre_choice_day='01'+this.pre_choice_day.substr(2);
    // var year = parseInt(this.pre_choice_day.substr(6));
    // var month = parseInt(this.pre_choice_day.substr(3,2));
    // var lastDay = new Date(year , month, 0).getDate();
    // this.nex_choice_day=lastDay+this.pre_choice_day.substr(2);
  }

  choice(j,k){
    for(var i=0;i<this.workers.length;i++){
      this.choice_day[i]=[];
      for(var o=0;o<(this.jours.length);o++){
        this.choice_day[i][o]=false;
      }
    }  
    this.choice_day[j][k]=true;
  }


  resetDate(day){
    this.year=parseInt(day.slice(6,10));
    this.affiche_month=(day.slice(3,5));
    this.month=parseInt(this.affiche_month);
    this.getMonthDate(this.year,this.month);
  }


  calculeDay(date){
    var year = parseInt(date.slice(6,10));
    var month = parseInt(date.slice(3,5))-1;
    var  day= parseInt(date.slice(0,2));
    var jour = new Date(year,month,day)
    if(jour.getDay()==0){
      return 'DIM'
    }else if(jour.getDay()==1){
      return 'LUN'
    }else if(jour.getDay()==2){
      return 'MAR'
    }else if(jour.getDay()==3){
      return 'MER'
    }else if(jour.getDay()==4){
      return 'JEU'
    }else if(jour.getDay()==5){
      return 'VEN'
    }else if(jour.getDay()==6){
      return 'SAM'
    }
  }
  calculeMonth(month){
    if(month==1){
      return 'JAN'
    }else if(month==2){
      return 'FEV'
    }else if(month==3){
      return 'MAR'
    }else if(month==4){
      return 'AVR'
    }else if(month==5){
      return 'MAI'
    }else if(month==6){
      return 'JUN'
    }else if(month==7){
      return 'JUI'
    }
    else if(month==8){
      return 'AOU'
    }
    else if(month==9){
      return 'SEP'
    }
    else if(month==10){
      return 'OCT'
    }
    else if(month==11){
      return 'NOV'
    }
    else if(month==12){
      return 'DEC'
    }
    
  }

  getMonthDate(year, month){
    this.ret=[];  
    if(!year || !month){
        var today = new Date();
        year = today.getFullYear();
        month = today.getMonth() + 1;
    }
    var firstDay = new Date(year, month-1, 1);//get the first day of month
    var firstDayWeekDay = firstDay.getDay();//get the day of week，for the order of row
    if(firstDayWeekDay == 0){//sunday
        firstDayWeekDay = 7;
    }

    year = firstDay.getFullYear();
    month = firstDay.getMonth() + 1;

    var lastDayOfLastMonth = new Date(year, month-1, 0);//get the last day of the last month
    var lastDateOfLastMonth = lastDayOfLastMonth.getDate();

    var preMonthDayCount = firstDayWeekDay - 1;
    var lastDay = new Date(year, month, 0);
    var lastDate = lastDay.getDate();

    for(var i=0; i<7*6; i++){
        var date = i + 1 - preMonthDayCount;
        var showDate = date;
        var thisMonth = month;
        //last month
        if(date <= 0){
            thisMonth = month - 1;
            showDate = lastDateOfLastMonth + date;
        }else if(date > lastDate){
            //next month
            thisMonth = month + 1;
            showDate = showDate -lastDate;
        } 
        if(thisMonth === 0){
            thisMonth = 12;
        }
        if(thisMonth === 13){
            thisMonth = 1;
        }
        this.ret.push({
            month: thisMonth,
            date: date,
            showDate: showDate,
            year:year
        })
        
    }

  };
  getInfo(pre_day,nex_day,filtre){
    this.mask=1;
    this.workers=[];
    var p_year = parseInt(pre_day.slice(6,10));
    var p_month = parseInt(pre_day.slice(3,5));
    var p_day= parseInt(pre_day.slice(0,2));
    var n_year = parseInt(nex_day.slice(6,10));
    var n_month = parseInt(nex_day.slice(3,5));
    var n_day= parseInt(nex_day.slice(0,2));
    if(p_year>n_year){
      this.popupService.alertOpen(this.text.pointer.date_error);
      this.mask=0; 
      return;
    }else if(p_year==n_year&&p_month>n_month){
      this.popupService.alertOpen(this.text.pointer.date_error);
      this.mask=0; 
      return;
    }else if(p_year==n_year&&p_month==n_month&&p_day>n_day){
      this.popupService.alertOpen(this.text.pointer.date_error);
      this.mask=0; 
      return;
    }
    this.idCompteClient = localStorage.getItem("cle_axe_client_rattachement");
    let tsql ="exec sp_festiming_get_bilan_pointage_process @cle_axe_client_rattachement={idCompteClient} , @code_rh_groupe='{code_rh_groupe}', @date_debut_inclus_jjmmaaaa  = '{date_debut_inclus_jjmmaaaa}' , @date_fin_inclus_jjmmaaaa='{date_fin_inclus_jjmmaaaa}',  @chaine_filtre='{chaine_filtre}'"
    //let tsql ="exec sp_festiming_get_bilan_pointage @cle_axe_client_rattachement={idCompteClient} , @date_debut_inclus_jjmmaaaa  = '{date_debut_inclus_jjmmaaaa}' , @date_fin_inclus_jjmmaaaa='{date_fin_inclus_jjmmaaaa}',  @chaine_filtre='{chaine_filtre}'"
    tsql = tsql.replace("{idCompteClient}",this.idCompteClient);
    tsql = tsql.replace("{code_rh_groupe}",localStorage.getItem("code_rh_groupe"));
    tsql = tsql.replace("{date_debut_inclus_jjmmaaaa}",pre_day);
    tsql = tsql.replace("{date_fin_inclus_jjmmaaaa}",nex_day);
    tsql = tsql.replace("{chaine_filtre}",filtre);

    // Matrice de simulation entre : 19/08/2019 au 08/09/2019 
    // exec sp_festiming_get_bilan_pointage @cle_axe_client_rattachement=13 , @date_debut_inclus_jjmmaaaa  = '19/08/2019' , @date_fin_inclus_jjmmaaaa='08/09/2019',  @chaine_filtre=''    


    this.data.SqlSelectToJson(tsql).subscribe 
      (
        (data:any) =>  {
          this.workers = data;
          if(this.workers[0].ReturnCode == "KO"){
            var message = this.workers[0].ReturnMessage;
            this.popupService.alertOpen(message);
            this.workers=[];
            this.mask=0;
          }
          console.log(data);
            },

        (error) =>       {
          console.log(error);
          this.popupService.alertOpen(this.text.pointer.sys_error);
          this.mask=0;                                        
          },
        () =>{
          this.jours = Object.keys(this.workers[0]);
           this.width=(this.jours.length-1)*150+"px";
           this.height=(this.workers.length)*50+"px";
           for(var i=0;i<this.workers.length;i++){
            this.choice_day[i]=[];
            for(var o=0;o<(this.jours.length)-4;o++){
              this.choice_day[i][o]=false;
            }
          }
          this.mask=0;
        } 
      );
     
  }

  formatDay(day,month,year){
    if(day<10){
      var choice_day;
      if(month<10){
        choice_day='0'+day+'/0'+month+'/'+year;
        return choice_day
      }else{
        choice_day='0'+day+'/'+month+'/'+year;
        return choice_day
      }
    }else{
      if(month<10){
        choice_day=day+'/0'+month+'/'+year;
        return choice_day
      }else{
        choice_day=day+'/'+month+'/'+year;
        return choice_day
      }
    }
  }

  edition(){
    var uid;
    var day;
    var etat_pointage;
    let nav: NavigationExtras ;
    outerloop:
    for(var i=0;i<this.workers.length;i++){
      for(var o=0;o<(this.jours.length);o++){
        if(this.choice_day[i][o]==true){
          uid = this.workers[i].uid_utilisateur;
          day = this.jours[o];
          etat_pointage = this.workers[i][day];
          nav = {queryParams:{
            date:day,
            id:uid,
            etat_pointage:etat_pointage
          }}
          break outerloop;
        }
      }
    }
    this.router.navigate(['/pointer/edition'],nav);
    return;
  }


  downLoad(){
    var file_name = localStorage.getItem("login_utilisateur");
    var confirm = window.confirm('Cette fonction exporte les dernières données actualisées. Confirmer ?');
    var pre_day = this.pre_choice_day.replace('/','-');
    pre_day = pre_day.replace('/','-');
    var nex_day = this.nex_choice_day.replace('/','-');
    nex_day = nex_day.replace('/','-');
    if(confirm){
      this.download.downloadFile(this.workers,file_name+'_'+pre_day+'_'+nex_day,this.jours);

    }else{
      return;
    }
  }

  test(e){
    e.preventDefault();
  }
  test1(e){
    if(e.button == 2){
      console.log("click right")
    }
  }


}

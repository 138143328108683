export class LoginData {

   
    public cle_axe_client_rattachement: string;
    public nom_client:string;
    public code_groupe:string;
  
    constructor () 
    {
      this.init();
    }
  

    init() 
    {
        this.cle_axe_client_rattachement = "";
        this.nom_client = "";
        this.code_groupe = "";

        // Récupération de Contexte de Cnx en automatique du LocalStorage
        this.cle_axe_client_rattachement = localStorage.getItem("cle_axe_client_rattachement");
        this.nom_client = localStorage.getItem("nom_client");
        this.code_groupe = localStorage.getItem("code_groupe");

    }


}
import { Component } from '@angular/core';
import { PopupService } from './services/popup.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  
  public login=0;
  constructor(
    public popupService:PopupService,
    private title:Title
  ){
    
  }

  ngOnInit(): void {
    this.TestStorage();
    
  }
  TestStorage(){
    var today = new Date().getTime();
    var day = localStorage.getItem("date")?parseInt(localStorage.getItem("date")):0;
    var limite = 1000*3600*24*7; 
    // 1000*3600*24*7;
    if(today-day>limite){
       localStorage.removeItem('loggedIn');
       localStorage.removeItem("login_utilisateur");
       localStorage.removeItem("libelle_utilisateur");
       localStorage.removeItem("cle_axe_client_rattachement");
       localStorage.removeItem("libelle_axe_client");
       localStorage.removeItem("code_profil");
       localStorage.removeItem("code_rh_groupe");
       localStorage.removeItem("actif_utilisateur");
    }
  }
}

// Modèle de données de la classe Property , permettant de paramétrer les contenus d'un objet pivotable

// Modèle de Données
// Convention : un fichier ts du modèle de donnée à placer dans App/models
// En général le modèle de données, correspond aux données coté IHM ou Concepteur Data
// Le constructeur définit les données du modèle

export class Stand 
{
    public mode_rotation: string;  // "auto" ou "manuel"
    public mode_media: string;     // "photo"  ou "video_youtube"  ou "video_mp4"
    public mode_page: string;      // "simple" ou "double"
    
    // **AVANT*****
    // Textes Avant
    // MEDIA
    public av_urlimg_frontend: string;
    public av_urlimg_backend: string;
    public av_urlvideo: string;
    
    // TEXTE
    public av_titre_big: string;
    public av_titre_light: string;
    public av_description: string;
    
    // Zone Bouton [Avant]
    public av_texte_bouton: string; // "Manual Rotation" ou "Auto Rotation"
    // ****************************************************************************



    // **ARRIERE*****
    // Textes Arrière
    public ar_titre_light: string;
    public ar_titre_big: string;
    public ar_description: string;

    // Zone compteurs ..............
    public ar_compteur1: string;
    public ar_compteur1_legende: string;

    public ar_compteur2: string;
    public ar_compteur2_legende: string;

    public ar_compteur3: string;
    public ar_compteur3_legende: string;

    // Zone lien réseaux sociaux ...........
    public ar_is_facebook: string;  // "oui" / "non"
    public ar_url_facebook: string; // Url

    public ar_is_googleplus: string;  // "oui" / "non"
    public ar_url_googleplus: string; // Url

    public ar_is_tweeter: string;  // "oui" / "non"
    public ar_url_tweeter: string; // Url
    
    // Zone Bouton [Arrière]
    public ar_texte_bouton: string; // "" ou "Back"
    // ****************************************************************************




    // Logique données par rapport à l'IHM ou Concepteur Data
    // En Ts un seul constructeur possible
    constructor()
                {
                   //this.title="";

                    this.mode_rotation = "auto"; // auto:rotation automatique ou manuel:rotation avec bouton de clic
                    this.mode_media = "photo";   // mode "photo" ou "video_youtube" ou "video_mp4"
                    this.mode_page = "double";   // "double" ou "simple"
                
                    this.av_urlimg_frontend = "/assets/images/rotating_card_profile.png"; // image FrontEnd - photo de face
                    this.av_urlimg_backend = "/assets/images/rotating_card_thumb3.png";   // image Backend - photo de fond
                    this.av_urlvideo = "https://www.youtube.com/embed/UgW2vdkTZIM";

                    this.av_titre_big="Doriscia MAKENLI";
                    this.av_titre_light = "Product Manager";
                    this.av_description = "Je suis calme et attentive. J'aime énormément les dossiers épineux.";
                    this.av_texte_bouton = "Auto Rotation";

                    this.ar_titre_light = "Être ou ne pas être, telle est la question!";
                    this.ar_titre_big = "Job Description";
                    this.ar_description = "Recueillir, Concevoir, Proposer, Définir, Assurer, Vérifier, Accompagner et Analyser l'information...";

                    this.ar_compteur1 = "10";
                    this.ar_compteur1_legende = "Passage";

                    this.ar_compteur2 = "5";
                    this.ar_compteur2_legende = "Interêt";

                    this.ar_compteur3 = "2";
                    this.ar_compteur3_legende = "Rendez-vous";

                    this.ar_is_facebook = "oui";
                    this.ar_url_facebook = "https://fr-fr.facebook.com/pages/category/Business-Service/Abiova-416419071809572";

                    this.ar_is_googleplus = "non";
                    this.ar_url_googleplus = "";

                    this.ar_is_tweeter = "non";
                    this.ar_url_tweeter = "";

                    this.ar_texte_bouton = "Retour";

                    this.SetfR_Auto();
                }             


SetfR_Auto()
{
    this.mode_rotation = "auto"; 
    this.av_texte_bouton = "Auto rotation";
    this.mode_page = "double";
    this.ar_texte_bouton = "";
}


SetfR_Manuel()
{
    this.mode_rotation = "manuel"; 
    this.av_texte_bouton = "Clic rotation";
    this.mode_page = "double";
    this.ar_texte_bouton = "Retour";
}


SetUrlStand(nostand:string, furl:string)
{
   
   
   /*
    this.mode_rotation = "auto"; // auto:rotation automatique ou manuel:rotation avec bouton de clic
    this.mode_media = "photo";   // mode "photo" ou "video_youtube" ou "video_mp4"
    this.mode_page = "simple";

    this.av_urlimg_frontend = "/assets/images/rotating_card_profile.png"; // image FrontEnd - photo de face
    this.av_urlimg_backend = "/assets/images/rotating_card_thumb3.png";   // image Backend - photo de fond
    this.av_urlvideo = "";

    this.av_titre_big="STAND N° " + nostand;
    this.av_titre_light = "..";
    this.av_description = "";
    this.av_texte_bouton = "";

    this.ar_titre_light = "?";
    this.ar_titre_big = "Stand - verso";
    this.ar_description = "";

    this.ar_compteur1 = "";
    this.ar_compteur1_legende = "";

    this.ar_compteur2 = "";
    this.ar_compteur2_legende = "";

    this.ar_compteur3 = "";
    this.ar_compteur3_legende = "";

    this.ar_is_facebook = "oui";
    this.ar_url_facebook = "https://fr-fr.facebook.com/pages/category/Business-Service/Abiova-416419071809572";
    this.ar_url_facebook = furl;

    this.ar_is_googleplus = "non";
    this.ar_url_googleplus = "";

    this.ar_is_tweeter = "non";
    this.ar_url_tweeter = "";

    this.ar_texte_bouton = "Retour";

    this.SetfR_Manuel();
    //this.mode_page = "simple";
*/

    
}



}
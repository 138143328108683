import { Component, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { isNgTemplate } from '@angular/compiler';
import { DataService } from 'src/app/data.service';
import { TextService } from 'src/app/services/text.service'
import { PopupService } from 'src/app/services/popup.service';

@Component({
  selector: 'app-modifier-planning',
  templateUrl: './modifier-planning.component.html',
  styleUrls: ['./modifier-planning.component.scss']
})
export class ModifierPlanningComponent implements OnInit {

  public idCompteClient;
  public name:any;
  public id:any; 
  public last_time:any;
  public days_time:any;
  public total:any;
  public act:any[]=[1,0,0,0];
  public plages:any[];

  inputTime(item){        
        var jour = '9999/01/01 ';
        if(item.f_time.length == 5&&!isNaN(item.f_time.substring(0, 1))&&!isNaN(item.f_time.substring(1, 2))&&
        item.f_time.substring(2, 3)==":"&&!isNaN(item.f_time.substring(3, 4))&&!isNaN(item.f_time.substring(4, 5))&&
        item.d_time.length == 5&&!isNaN(item.d_time.substring(0, 1))&&!isNaN(item.d_time.substring(1, 2))&&
        item.d_time.substring(2, 3)==":"&&!isNaN(item.d_time.substring(3, 4))&&!isNaN(item.d_time.substring(4, 5))&&
        !isNaN(item.pause)&&!isNaN(item.d_to_av)&&!isNaN(item.d_to_af)&&!isNaN(item.f_to_av)&&!isNaN(item.f_to_af)&&
        item.d_to_af<600&&item.d_to_av<600&&item.f_to_af<600&&item.f_to_av<600&&
        item.d_time!="24:00"&&item.f_time!="24:00"){
       
        }else{
          this.popupService.alertOpen(this.texte.text_modify_planning.alert_sauv+item.id);
  
          return;
        }
        var result = this.calculeSecond(item.f_time,item.d_time)-item.pause*60*1000;
        if (result>0){
          item.disable_case=false;
          item.calcule=this.calculeTime(result);
          this.daysCase();
        
        }else if(result==0){
          if(item.d_time=="00:00"&&item.f_time=="00:00"){
            item.disable_case=true;
            item.case=0;
            item.calcule="00:00";
          }else{
            
              this.popupService.alertOpen(this.texte.text_modify_planning.alert_sauv+item.id);
              item.calcule='00:00';
              item.disable_case=true;
              item.case=0;
              for(var i=0;i<item.days.length;i++){
                item.days[i].value=0;
              }
              this.daysCase();
              this.perCase(item);
              return;
           
          }
        }else if(result<0){
          var conf=confirm(this.texte.text_modify_planning.alert_nuit);
          if(conf==true){
            result = result + 24*1000*3600;
            if(result<0){
              this.popupService.alertOpen(this.texte.text_modify_planning.alert_sauv+item.id);
              item.calcule='00:00';
              item.disable_case=true;
              item.case=0;
              for(var i=0;i<item.days.length;i++){
                item.days[i].value=0;
              }
              this.daysCase();
              this.perCase(item);
              return;
            }
            item.calcule=this.calculeTime(result);
            this.daysCase();
            item.disable_case=false;
          }else{
            this.popupService.alertOpen(this.texte.text_modify_planning.alert_sauv+item.id);
            item.calcule='00:00';
            item.disable_case=true;
            item.case=0;
            for(var i=0;i<item.days.length;i++){
              item.days[i].value=0;
            }
            this.daysCase();
            this.perCase(item);
            return;
          }
       
        }else{
            this.popupService.alertOpen(this.texte.text_modify_planning.alert_sauv+item.id);
            item.calcule='00:00';
            item.d_time='00:00';
            item.f_time='00:00';
            item.disable_case=true;
            item.case=0;
            for(var i=0;i<item.days.length;i++){
              item.days[i].value=0;
            }
            this.daysCase();
            this.perCase(item);
        }
    }
  

  daysCase(){
    if(this.plages[0].per==1){
      for(var i=0;i<this.plages.length;i++){
        for(var j=0;j<this.plages[i].days.length;j++){
          this.plages[i].days[j].value=0;
        }
      }
    }
    for(var i=0;i<this.days_time.length;i++){
      var time=0;
      for(var j=0;j<this.plages.length;j++){
        if(this.plages[j].days[i].value!=0&&i!=7){         
          var s = this.calculeSecond(this.plages[j].calcule,'00:00')
           time +=s;
        }else if(i==7){
        }
      }
      if(time<=0&&i!=7){
        this.days_time[i]='00:00';
      }else if(i!=7){
       this.days_time[i]=this.calculeTime(time);
      }
    }
    this.calculeTotal()
  }
  

  caseCheck(item){  
    if(item.case==0){
      for(var i=0;i<item.days.length;i++){
        item.days[i].value=0;
      }
      this.daysCase();
      this.calculeTotal();
      this.perCase(item);
    }else{
      this.inputTime(item);
      this.perCase(item);
    }
  }
  perCase(e){
    if(e.per==0){
      for(var i=0;i<this.plages.length;i++){
        this.plages[i].per=0;
      }
      this.last_time='00:00';
    }else if(e.per==1){
      var time=0;
      for(var i=0;i<this.plages.length;i++){
        this.plages[i].per=1;
      }
      for(var i=0;i<this.plages.length;i++){
        for(var j=0;j<this.plages[i].days.length;j++){
          this.plages[i].days[j].value=0;
        }
        if(this.plages[i].case==1)
          {time  += this.calculeSecond(this.plages[i].calcule,'00:00');}
      }
      this.last_time=this.calculeTime(time);
      this.daysCase();
    }
  }

  constructor(
    public router:Router,
    public active:ActivatedRoute,
    private data: DataService,
    public texte:TextService,
    private popupService:PopupService
    ) {}

  ngOnInit() {
    this.active.queryParams.subscribe((data)=>{
      this.name=data.pname;    
      this.id=data.pid;
      this.getInfo();
      if(this.name.length>16){
        this.name = this.name.slice(0,22)+'...';
      }
    })
  }

  goHome(){
    this.router.navigate(['/planning'])
  }
  
  calculeTime(time){
    if(time<=0){
      return '00:00'
    }
    var result;
    var hour = Math.floor(time/(3600*1000));
    var minute = Math.floor((time%(3600*1000))/(60*1000));
        if(hour>9){
          if(minute>9){
            result=hour+":"+minute;
          }else{
            result=hour+':0'+minute;
          }
        }else{
          if(minute>9){
            result='0'+hour+':'+minute;
          }else{
            result='0'+hour+':0'+minute;
          }
        }
      return result;
  }
  calculeSecond(time1,time2){
    var jour = '9999/01/01 ';
    var d1:any = new Date(jour+time1);
    var d2:any = new Date(jour+time2);
    return (d1-d2)
  }

  calculeTotal(){
    var total=0 
    for(var i=0;i<this.days_time.length;i++){      
      total+=this.calculeSecond(this.days_time[i],'00:00');
    }
    
    this.total=this.calculeTime(total);
  }
  recalcule(){
    this.daysCase();
  }

  getInfo(){
    this.idCompteClient = localStorage.getItem("cle_axe_client_rattachement");
    let tsql = "exec sp_festiming_list_PARAM_JOURNEE_TYPE_GET_TIMESZONE @cle_axe_client_rattachement={idCompteClient}, @id_to_get={id}";
																
    tsql = tsql.replace("{idCompteClient}",this.idCompteClient);
    tsql = tsql.replace("{id}",this.id); // Id item planning	

    this.data.SqlSelectToJson(tsql).subscribe 
      (
        (data:any) =>  {
          let info=data[0];

          console.log(data[0]);
           this.plages=[
             {
               id:1,
               case:(info.d1_plage_actif)=='False'?0:1,
               disable_case:false,
               d_to_av:(info.d1_hhmm_tol_avant=='')?'15':info.d1_hhmm_tol_avant,
               d_to_af:(info.d1_hhmm_tol_apres=='')?'15':info.d1_hhmm_tol_apres,
               d_time:(info.d1_hhmm=='')?'07:30':info.d1_hhmm,
               f_to_av:(info.f1_hhmm_tol_avant=='')?'15':info.f1_hhmm_tol_avant,
               f_to_af:(info.f1_hhmm_tol_apres=='')?'15':info.f1_hhmm_tol_apres,
               f_time:(info.f1_hhmm=='')?'12:00':info.f1_hhmm,
               pause:(info.decompte_pause1=='')?'30':info.decompte_pause1,
               calcule:(info.d1_plage_temps_de_travail=='')?'04:00':info.d1_plage_temps_de_travail,
               per:(info.Ps1=='True')?1:0,
               days:[
                 {
                   day:this.texte.text_modify_planning.lun,
                   value:info.Lu1=='False'?0:1,
                 },
                 {
                   day:this.texte.text_modify_planning.mar,
                   value:info.Ma1=='False'?0:1,
                 },
                 {
                   day:this.texte.text_modify_planning.mer,
                   value:info.Me1=='False'?0:1,
                 },
                 {
                   day:this.texte.text_modify_planning.jeu,
                   value:info.Je1=='False'?0:1,
                 },
                 {
                   day:this.texte.text_modify_planning.ven,
                   value:info.Ve1=='False'?0:1,
                 },
                {
                  day:this.texte.text_modify_planning.sam,
                  value:info.Sa1=='True'?1:0
                },{
                  day:this.texte.text_modify_planning.dim,
                  value:info.Di1=='True'?1:0
                },{
                  day:this.texte.text_modify_planning.fer,
                  value:info.Fe1=='True'?1:0,
                }
             ]
           },
           {
             id:2,
             case:(info.d2_plage_actif)=='False'?0:1,
             disable_case:false,
             d_to_av:(info.d2_hhmm_tol_avant=='')?'15':info.d2_hhmm_tol_avant,
             d_to_af:(info.d2_hhmm_tol_apres=='')?'15':info.d2_hhmm_tol_apres,
             d_time:(info.d2_hhmm=='')?'14:30':info.d2_hhmm,
             f_to_av:(info.f2_hhmm_tol_avant=='')?'15':info.f2_hhmm_tol_avant,
             f_to_af:(info.f2_hhmm_tol_apres=='')?'15':info.f2_hhmm_tol_apres,
             f_time:(info.f2_hhmm=='')?'18:00':info.f2_hhmm,
             pause:(info.decompte_pause2=='')?'30':info.decompte_pause2,
             calcule:(info.d2_plage_temps_de_travail=='')?'03:00':info.d2_plage_temps_de_travail,
             per:(info.Ps2=='True')?1:0,
             days:[
              {
                day:this.texte.text_modify_planning.lun,
                value:info.Lu2=='False'?0:1,
              },
              {
                day:this.texte.text_modify_planning.mar,
                value:info.Ma2=='False'?0:1,
              },
              {
                day:this.texte.text_modify_planning.mer,
                value:info.Me2=='False'?0:1,
              },
              {
                day:this.texte.text_modify_planning.jeu,
                value:info.Je2=='False'?0:1,
              },
              {
                day:this.texte.text_modify_planning.ven,
                value:info.Ve2=='False'?0:1,
              },
              {
                day:this.texte.text_modify_planning.sam,
                value:info.Sa2=='True'?1:0
              },{
                day:this.texte.text_modify_planning.dim,
                value:info.Di2=='True'?1:0
              },{
                day:this.texte.text_modify_planning.fer,
                value:info.Fe2=='True'?1:0,
              }
          ]
             
           },
           {
             id:3,
             case:(info.d3_plage_actif)=='True'?1:0,
             disable_case:false,
             d_to_av:info.d3_hhmm_tol_avant==''?0:info.d3_hhmm_tol_avant,
             d_to_af:info.d3_hhmm_tol_apres==''?0:info.d3_hhmm_tol_apres,
             d_time:(info.d3_hhmm=='')?'00:00':info.d3_hhmm,
             f_to_av:info.f3_hhmm_tol_avant==''?0:info.f3_hhmm_tol_avant,
             f_to_af:info.f3_hhmm_tol_apres==''?0:info.f3_hhmm_tol_apres,
             f_time:(info.f3_hhmm=='')?'00:00':info.f3_hhmm,
             pause:info.decompte_pause3==''?0:info.decompte_pause3,
             calcule:(info.d3_plage_temps_de_travail=='')?'00:00':info.d3_plage_temps_de_travail,
             per:(info.Ps3=='True')?1:0,
             days:[
              {
                day:this.texte.text_modify_planning.lun,
                value:info.Lu3=='True'?1:0
              },
              {
                day:this.texte.text_modify_planning.mar,
                value:info.Ma3=='True'?1:0
              },
              {
                day:this.texte.text_modify_planning.mer,
                value:info.Me3=='True'?1:0
              },
              {
                day:this.texte.text_modify_planning.jeu,
                value:info.Je3=='True'?1:0
              },
              {
                day:this.texte.text_modify_planning.ven,
                value:info.Ve3=='True'?1:0
              },
              {
                day:this.texte.text_modify_planning.sam,
                value:info.Sa3=='True'?1:0
              },{
                day:this.texte.text_modify_planning.dim,
                value:info.Di3=='True'?1:0
              },{
                day:this.texte.text_modify_planning.fer,
                value:info.Fe3=='True'?1:0
              }
             ]
           },
           {
             id:4,
             case:(info.d4_plage_actif)=='True'?1:0,
             disable_case:false,
             d_to_av:info.d4_hhmm_tol_avant==''?0:info.d4_hhmm_tol_avant,
             d_to_af:info.d4_hhmm_tol_apres==''?0:info.d4_hhmm_tol_apres,
             d_time:(info.d4_hhmm=='')?'00:00':info.d4_hhmm,
             f_to_av:info.f4_hhmm_tol_avant==''?0:info.f4_hhmm_tol_avant,
             f_to_af:info.f4_hhmm_tol_apres==''?0:info.f4_hhmm_tol_apres,
             f_time:(info.f4_hhmm=='')?'00:00':info.f4_hhmm,
             pause:info.decompte_pause4==''?0:info.decompte_pause4,
             calcule:(info.d4_plage_temps_de_travail=='')?'00:00':info.d4_plage_temps_de_travail,
             per:(info.Ps4=='True')?1:0,
             days:[
              {
                day:this.texte.text_modify_planning.lun,
                value:info.Lu4=='True'?1:0
              },
              {
                day:this.texte.text_modify_planning.mar,
                value:info.Ma4=='True'?1:0
              },
              {
                day:this.texte.text_modify_planning.mer,
                value:info.Me4=='True'?1:0
              },
              {
                day:this.texte.text_modify_planning.jeu,
                value:info.Je4=='True'?1:0
              },
              {
                day:this.texte.text_modify_planning.ven,
                value:info.Ve4=='True'?1:0
              },
              {
                day:this.texte.text_modify_planning.sam,
                value:info.Sa4=='True'?1:0
              },{
                day:this.texte.text_modify_planning.dim,
                value:info.Di4=='True'?1:0
              },{
                day:this.texte.text_modify_planning.fer,
                value:info.Fe4=='True'?1:0
              }
             ]
           }
         ];
         this.days_time=[info.total_plages_temps_de_travail_lun==''?'07:00':info.total_plages_temps_de_travail_lun,
                         info.total_plages_temps_de_travail_mar==''?'07:00':info.total_plages_temps_de_travail_mar,
                         info.total_plages_temps_de_travail_mer==''?'07:00':info.total_plages_temps_de_travail_mer,
                         info.total_plages_temps_de_travail_jeu==''?'07:00':info.total_plages_temps_de_travail_jeu,
                         info.total_plages_temps_de_travail_ven==''?'07:00':info.total_plages_temps_de_travail_ven,
                         info.total_plages_temps_de_travail_sam==''?'00:00':info.total_plages_temps_de_travail_sam,
                         info.total_plages_temps_de_travail_dim==''?'00:00':info.total_plages_temps_de_travail_dim,
                         '  '
          ];
          this.total=info.total_plages_temps_de_travail_lun_mar_mer_jeu_ven_sam_dim==''?'35:00':info.total_plages_temps_de_travail_lun_mar_mer_jeu_ven_sam_dim;
          this.last_time=info.total_plages_temps_de_travail_per==''?'00:00':info.total_plages_temps_de_travail_per;
        },
        (error) =>{this.popupService.alertOpen(error);},
        () =>{
          for(var i=0;i<this.plages.length;i++){
            if(this.plages[i].calcule=='00:00'){
              this.plages[i].disable_case=true;
            }
          }
        } 
            );
  }
  changeInfo(){
    for(var j=0;j<this.plages.length;j++){
      var jour = '9999/01/01 ';
      var item = this.plages[j];

      
      if(item.f_time.length == 5&&!isNaN(item.f_time.substring(0, 1))&&!isNaN(item.f_time.substring(1, 2))&&
         item.f_time.substring(2, 3)==":"&&!isNaN(item.f_time.substring(3, 4))&&!isNaN(item.f_time.substring(4, 5))&&
         item.d_time.length == 5&&!isNaN(item.d_time.substring(0, 1))&&!isNaN(item.d_time.substring(1, 2))&&
         item.d_time.substring(2, 3)==":"&&!isNaN(item.d_time.substring(3, 4))&&!isNaN(item.d_time.substring(4, 5))&&
         !isNaN(item.pause)&&!isNaN(item.d_to_av)&&!isNaN(item.d_to_af)&&!isNaN(item.f_to_av)&&!isNaN(item.f_to_af)&&
         item.d_to_af<600&&item.d_to_av<600&&item.f_to_af<600&&item.f_to_av<600&&
         item.d_time!="24:00"&&item.f_time!="24:00"){
        
      }else{
        this.popupService.alertOpen(this.texte.text_modify_planning.alert_sauv+item.id);

        return;
      }
      var result = this.calculeSecond(item.f_time,item.d_time)-item.pause*60*1000;
      if (result>0){
        item.disable_case=false;
        item.calcule=this.calculeTime(result);
        this.daysCase();

      }else if(result==0){
        if(item.d_time=="00:00"&&item.f_time=="00:00"){
          item.disable_case=true;
          item.case=0;
          item.calcule="00:00";
        }else{
         
          this.popupService.alertOpen(this.texte.text_modify_planning.alert_sauv+item.id);
          item.calcule='00:00';
          item.disable_case=true;
          item.case=0;
          for(var i=0;i<item.days.length;i++){
            item.days[i].value=0;
          }
          this.daysCase();
          this.perCase(item);
          return;
        }
      }else if(result<0){
        var conf=confirm(this.texte.text_modify_planning.alert_nuit);
        if(conf==true){
          result = result + 24*1000*3600;
          if(result<0){
            this.popupService.alertOpen(this.texte.text_modify_planning.alert_sauv+item.id);
            item.calcule='00:00';
            item.disable_case=true;
            item.case=0;
            for(var i=0;i<item.days.length;i++){
              item.days[i].value=0;
            }
            this.daysCase();
            this.perCase(item);
            return;
          }
          item.calcule=this.calculeTime(result);
          this.daysCase();
        }else{
          this.popupService.alertOpen(this.texte.text_modify_planning.alert_sauv+item.id);
          item.calcule='00:00';
          item.disable_case=true;
          item.case=0;
          for(var i=0;i<item.days.length;i++){
            item.days[i].value=0;
          }
          this.daysCase();
          this.perCase(item);
          return;
        }
     
      }else{
        if(item.case==0){
          this.popupService.alertOpen(this.texte.text_modify_planning.alert_sauv+item.id);
          item.calcule='00:00';
          item.d_time='00:00';
          item.f_time='00:00';
          item.disable_case=true;
          item.case=0;
          for(var i=0;i<item.days.length;i++){
            item.days[i].value=0;
          }
          this.daysCase();
          this.perCase(item);
        }else if(item.case==1){
          this.popupService.alertOpen(this.texte.text_modify_planning.alert_sauv+item.id);
          return;
        } 
      }
    }
    // console.log(this.plages);
    this.idCompteClient = localStorage.getItem("cle_axe_client_rattachement");
    let tsql = `exec sp_festiming_list_PARAM_JOURNEE_TYPE_UPDATE_TIMESZONE 
      @cle_axe_client_rattachement={idCompteClient}, 
      @id_to_update={id},
      @d1_plage_actif={d1_plage_actif},
      @d1_plage_temps_de_travail='{d1_plage_temps_de_travail}',
      @d1_hhmm='{d1_hhmm}',
      @f1_hhmm='{f1_hhmm}',
      @Lu1={Lu1},
      @Ma1={Ma1},
      @Me1={Me1},
      @Je1={Je1},
      @Ve1={Ve1},
      @Sa1={Sa1},
      @Di1={Di1},
      @Fe1={Fe1},
      @Ps1={Ps1},
      @d1_hhmm_tol_avant={d1_hhmm_tol_avant},
      @d1_hhmm_tol_apres={d1_hhmm_tol_apres},
      @f1_hhmm_tol_avant={f1_hhmm_tol_avant},
      @f1_hhmm_tol_apres={f1_hhmm_tol_apres},
      @decompte_pause1={decompte_pause1},
      @d2_plage_actif={d2_plage_actif},
      @d2_plage_temps_de_travail='{d2_plage_temps_de_travail}',
      @d2_hhmm='{d2_hhmm}',
      @f2_hhmm='{f2_hhmm}',
      @Lu2={Lu2},
      @Ma2={Ma2},
      @Me2={Me2},
      @Je2={Je2},
      @Ve2={Ve2},
      @Sa2={Sa2},
      @Di2={Di2},
      @Fe2={Fe2},
      @Ps2={Ps2},
      @d2_hhmm_tol_avant={d2_hhmm_tol_avant},
      @d2_hhmm_tol_apres={d2_hhmm_tol_apres},
      @f2_hhmm_tol_avant={f2_hhmm_tol_avant},
      @f2_hhmm_tol_apres={f2_hhmm_tol_apres},
      @decompte_pause2={decompte_pause2},
      @d3_plage_actif={d3_plage_actif},
      @d3_plage_temps_de_travail='{d3_plage_temps_de_travail}',
      @d3_hhmm='{d3_hhmm}',
      @f3_hhmm='{f3_hhmm}',
      @Lu3={Lu3},
      @Ma3={Ma3},
      @Me3={Me3},
      @Je3={Je3},
      @Ve3={Ve3},
      @Sa3={Sa3},
      @Di3={Di3},
      @Fe3={Fe3},
      @Ps3={Ps3},
      @d3_hhmm_tol_avant={d3_hhmm_tol_avant},
      @d3_hhmm_tol_apres={d3_hhmm_tol_apres},
      @f3_hhmm_tol_avant={f3_hhmm_tol_avant},
      @f3_hhmm_tol_apres={f3_hhmm_tol_apres},
      @decompte_pause3={decompte_pause3},
      @d4_plage_actif={d4_plage_actif},
      @d4_plage_temps_de_travail='{d4_plage_temps_de_travail}',
      @d4_hhmm='{d4_hhmm}',
      @f4_hhmm='{f4_hhmm}',
      @Lu4={Lu4},
      @Ma4={Ma4},
      @Me4={Me4},
      @Je4={Je4},
      @Ve4={Ve4},
      @Sa4={Sa4},
      @Di4={Di4},
      @Fe4={Fe4},
      @Ps4={Ps4},
      @d4_hhmm_tol_avant={d4_hhmm_tol_avant},
      @d4_hhmm_tol_apres={d4_hhmm_tol_apres},
      @f4_hhmm_tol_avant={f4_hhmm_tol_avant},
      @f4_hhmm_tol_apres={f4_hhmm_tol_apres},
      @decompte_pause4={decompte_pause4},
      @total_plages_temps_de_travail_lun='{total_plages_temps_de_travail_lun}',
      @total_plages_temps_de_travail_mar='{total_plages_temps_de_travail_mar}',
      @total_plages_temps_de_travail_mer='{total_plages_temps_de_travail_mer}',
      @total_plages_temps_de_travail_jeu='{total_plages_temps_de_travail_jeu}',
      @total_plages_temps_de_travail_ven='{total_plages_temps_de_travail_ven}',
      @total_plages_temps_de_travail_sam='{total_plages_temps_de_travail_sam}',
      @total_plages_temps_de_travail_dim='{total_plages_temps_de_travail_dim}',
      @total_plages_temps_de_travail_fer='{total_plages_temps_de_travail_fer}',
      @total_plages_temps_de_travail_per='{total_plages_temps_de_travail_per}',
      @total_plages_temps_de_travail_lun_mar_mer_jeu_ven_sam_dim='{total_plages_temps_de_travail_lun_mar_mer_jeu_ven_sam_dim}'
    `;
      tsql = tsql.replace("{idCompteClient}",this.idCompteClient);
      tsql = tsql.replace("{id}",this.id); 
   

        tsql = tsql.replace("{d1_plage_actif}",this.plages[0].case); // flag coch?non coch?
        tsql = tsql.replace("{d1_plage_temps_de_travail}",this.plages[0].calcule); //hh:mm	
        tsql = tsql.replace("{d1_hhmm}",this.plages[0].d_time); // hh:mm
        tsql = tsql.replace("{f1_hhmm}",this.plages[0].f_time); // hh:mm
        tsql = tsql.replace("{Lu1}",this.plages[0].days[0].value); // lun : flag coch?non coch?
        tsql = tsql.replace("{Ma1}",this.plages[0].days[1].value); // mar : flag coch?non coch?
        tsql = tsql.replace("{Me1}",this.plages[0].days[2].value); // mer : flag coch?non coch?
        tsql = tsql.replace("{Je1}",this.plages[0].days[3].value); // jeu : flag coch?non coch?
        tsql = tsql.replace("{Ve1}",this.plages[0].days[4].value); // ven : flag coch?non coch?
        tsql = tsql.replace("{Sa1}",this.plages[0].days[5].value); // sam : flag coch?non coch?
        tsql = tsql.replace("{Di1}",this.plages[0].days[6].value); // dim : flag coch?non coch?
        tsql = tsql.replace("{Fe1}",this.plages[0].days[7].value);
        tsql = tsql.replace("{Ps1}",this.plages[0].per==1?'True':'False');  // personnalis?: flag coch?non coch?
        tsql = tsql.replace("{d1_hhmm_tol_avant}",this.plages[0].d_to_av);  // nb minutes
        tsql = tsql.replace("{d1_hhmm_tol_apres}",this.plages[0].d_to_af);  // nb minutes
        tsql = tsql.replace("{f1_hhmm_tol_avant}",this.plages[0].f_to_av);  // nb minutes
        tsql = tsql.replace("{f1_hhmm_tol_apres}",this.plages[0].f_to_af);  // nb minutes
        tsql = tsql.replace("{decompte_pause1}",this.plages[0].pause);  // nb minutes
        
        tsql = tsql.replace("{d2_plage_actif}",this.plages[1].case); // flag coch?non coch?
        tsql = tsql.replace("{d2_plage_temps_de_travail}",this.plages[1].calcule); //hh:mm	
        tsql = tsql.replace("{d2_hhmm}",this.plages[1].d_time); // hh:mm
        tsql = tsql.replace("{f2_hhmm}",this.plages[1].f_time); // hh:mm
        tsql = tsql.replace("{Lu2}",this.plages[1].days[0].value); // lun : flag coch?non coch?
        tsql = tsql.replace("{Ma2}",this.plages[1].days[1].value); // mar : flag coch?non coch?
        tsql = tsql.replace("{Me2}",this.plages[1].days[2].value); // mer : flag coch?non coch?
        tsql = tsql.replace("{Je2}",this.plages[1].days[3].value); // jeu : flag coch?non coch?
        tsql = tsql.replace("{Ve2}",this.plages[1].days[4].value); // ven : flag coch?non coch?
        tsql = tsql.replace("{Sa2}",this.plages[1].days[5].value); // sam : flag coch?non coch?
        tsql = tsql.replace("{Di2}",this.plages[1].days[6].value); // dim : flag coch?non coch?
        tsql = tsql.replace("{Fe2}",this.plages[1].days[7].value);
        tsql = tsql.replace("{Ps2}",this.plages[1].per==1?'True':'False');  // personnalis?: flag coch?non coch?
        tsql = tsql.replace("{d2_hhmm_tol_avant}",this.plages[1].d_to_av);  // nb minutes
        tsql = tsql.replace("{d2_hhmm_tol_apres}",this.plages[1].d_to_af);  // nb minutes
        tsql = tsql.replace("{f2_hhmm_tol_avant}",this.plages[1].f_to_av);  // nb minutes
        tsql = tsql.replace("{f2_hhmm_tol_apres}",this.plages[1].f_to_af);  // nb minutes
        tsql = tsql.replace("{decompte_pause2}",this.plages[1].pause);  // nb minutes

        tsql = tsql.replace("{d3_plage_actif}",this.plages[2].case); // flag coch?non coch?
        tsql = tsql.replace("{d3_plage_temps_de_travail}",this.plages[2].calcule); //hh:mm	
        tsql = tsql.replace("{d3_hhmm}",this.plages[2].d_time); // hh:mm
        tsql = tsql.replace("{f3_hhmm}",this.plages[2].f_time); // hh:mm
        tsql = tsql.replace("{Lu3}",this.plages[2].days[0].value); // lun : flag coch?non coch?
        tsql = tsql.replace("{Ma3}",this.plages[2].days[1].value); // mar : flag coch?non coch?
        tsql = tsql.replace("{Me3}",this.plages[2].days[2].value); // mer : flag coch?non coch?
        tsql = tsql.replace("{Je3}",this.plages[2].days[3].value); // jeu : flag coch?non coch?
        tsql = tsql.replace("{Ve3}",this.plages[2].days[4].value); // ven : flag coch?non coch?
        tsql = tsql.replace("{Sa3}",this.plages[2].days[5].value); // sam : flag coch?non coch?
        tsql = tsql.replace("{Di3}",this.plages[2].days[6].value); // dim : flag coch?non coch?
        tsql = tsql.replace("{Fe3}",this.plages[2].days[7].value);
        tsql = tsql.replace("{Ps3}",this.plages[2].per==1?'True':'False');  // personnalis?: flag coch?non coch?
        tsql = tsql.replace("{d3_hhmm_tol_avant}",this.plages[2].d_to_av);  // nb minutes
        tsql = tsql.replace("{d3_hhmm_tol_apres}",this.plages[2].d_to_af);  // nb minutes
        tsql = tsql.replace("{f3_hhmm_tol_avant}",this.plages[2].f_to_av);  // nb minutes
        tsql = tsql.replace("{f3_hhmm_tol_apres}",this.plages[2].f_to_af);  // nb minutes
        tsql = tsql.replace("{decompte_pause3}",this.plages[2].pause);  // nb minutes0

        tsql = tsql.replace("{d4_plage_actif}",this.plages[3].case); // flag coch?non coch?
        tsql = tsql.replace("{d4_plage_temps_de_travail}",this.plages[3].calcule); //hh:mm	
        tsql = tsql.replace("{d4_hhmm}",this.plages[3].d_time); // hh:mm
        tsql = tsql.replace("{f4_hhmm}",this.plages[3].f_time); // hh:mm
        tsql = tsql.replace("{Lu4}",this.plages[3].days[0].value); // lun : flag coch?non coch?
        tsql = tsql.replace("{Ma4}",this.plages[3].days[1].value); // mar : flag coch?non coch?
        tsql = tsql.replace("{Me4}",this.plages[3].days[2].value); // mer : flag coch?non coch?
        tsql = tsql.replace("{Je4}",this.plages[3].days[3].value); // jeu : flag coch?non coch?
        tsql = tsql.replace("{Ve4}",this.plages[3].days[4].value); // ven : flag coch?non coch?
        tsql = tsql.replace("{Sa4}",this.plages[3].days[5].value); // sam : flag coch?non coch?
        tsql = tsql.replace("{Di4}",this.plages[3].days[6].value); // dim : flag coch?non coch?
        tsql = tsql.replace("{Fe4}",this.plages[3].days[7].value);
        tsql = tsql.replace("{Ps4}",this.plages[3].per==1?'True':'False');  // personnalis?: flag coch?non coch?
        tsql = tsql.replace("{d4_hhmm_tol_avant}",this.plages[3].d_to_av);  // nb minutes
        tsql = tsql.replace("{d4_hhmm_tol_apres}",this.plages[3].d_to_af);  // nb minutes
        tsql = tsql.replace("{f4_hhmm_tol_avant}",this.plages[3].f_to_av);  // nb minutes
        tsql = tsql.replace("{f4_hhmm_tol_apres}",this.plages[3].f_to_af);  // nb minutes
        tsql = tsql.replace("{decompte_pause4}",this.plages[3].pause);  // nb minutes  
          // Totaux plages
      tsql = tsql.replace("{total_plages_temps_de_travail_lun}",this.days_time[0]); // hh:mm
      tsql = tsql.replace("{total_plages_temps_de_travail_mar}",this.days_time[1]); // hh:mm
      tsql = tsql.replace("{total_plages_temps_de_travail_mer}",this.days_time[2]); // hh:mm
      tsql = tsql.replace("{total_plages_temps_de_travail_jeu}",this.days_time[3]); // hh:mm
      tsql = tsql.replace("{total_plages_temps_de_travail_ven}",this.days_time[4]); // hh:mm
      tsql = tsql.replace("{total_plages_temps_de_travail_sam}",this.days_time[5]); // hh:mm
      tsql = tsql.replace("{total_plages_temps_de_travail_dim}",this.days_time[6]); // hh:mm
      tsql = tsql.replace("{total_plages_temps_de_travail_fer}",this.days_time[7]); // hh:mm (f閞i?
      tsql = tsql.replace("{total_plages_temps_de_travail_per}",this.last_time); // hh:mm  ( personnalis?)
      tsql = tsql.replace("{total_plages_temps_de_travail_lun_mar_mer_jeu_ven_sam_dim}",this.total);
      console.log(this.plages);
 this.data.SqlSelectToJson(tsql).subscribe 
 (
   (data:any) =>  {

    this.popupService.alertOpen(this.texte.text_modify_planning.alert_op_eff);
      this.goHome();  
       },

   (error) =>{                                   
     this.popupService.alertOpen(this.texte.text_modify_planning.alert_err);
      },
                                   
     () => {} 
       );
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class PopupService {

  public popupOpen = 0;
  public title = '';
  public alert = 0;
  public confirm = 0;
  public result:boolean;
  public confirmResult:any;

  alertOpen(title:string){
    this.popupOpen=1;
    this.alert=1;
    this.title = title;
   
  }
  confirmeOpen(title:string){
    // if(this.popupOpen==0){
    //   this.title = title;
    //   this.popupOpen=1;
    //   this.confirm=1;
    // }else{
    //   this.popupOpen=0;
    //   this.confirm=0;
      
    // }
    this.popupOpen=1;
    this.confirm=1;
    this.confirmResult.subscribe((res)=>{
      this.confirm=0;
      this.popupOpen=0;
      console.log(res);
      return res;
    });
    
  }
   
  constructor() { }
}
 

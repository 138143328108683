import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { debounceTime } from 'rxjs/operators';
import { Router, NavigationExtras } from '@angular/router';
import { FormControl } from '@angular/forms';
import { DataService } from 'src/app/data.service';
import { TextService } from 'src/app/services/text.service'
import { PopupService } from 'src/app/services/popup.service';

@Component({
  selector: 'app-design-planning',
  templateUrl: './design-planning.component.html',
  styleUrls: ['./design-planning.component.scss']
})
export class DesignPlanningComponent implements OnInit {

  
  public idCompteClient:any;
  public route:string;


  public titleFilter:FormControl = new FormControl();
  public keyword:any='';

  public control:boolean[]=[];
  public or_control:boolean[]=[false,false];
  public search_control:boolean=false;
  constructor(
    private data: DataService,
    public router:Router,
    public text:TextService,
    private popupService:PopupService
  ) { 
   
    this.titleFilter.valueChanges.pipe(
      debounceTime(500)).subscribe(
      value=>this.keyword = value
    );
  }
    public groupePlanning:any[]=[];
  ngOnInit() {
    this.getInfo(); 
    
    }

  reset(){
    this.or_control=[false,false];
    this.search_control=false;
    for(var i=0;i<this.control.length;i++){
      this.control[i]=false;
    } 
  }


  select(e){
    this.reset();
    this.control[e]=true;
  }

  goModify(){    
    for(var i=0;i<this.control.length;i++){
      if(this.control[i]===true){
        let info:NavigationExtras={queryParams:{'pid':this.groupePlanning[i].id,'pname':this.groupePlanning[i].designation}};
        this.router.navigate(['/planning/modify'],info);
      }else{

      }  
    }
     return;
  }

  add(){
    var title:any = prompt(this.text.text_plan.confirmPlanning);
    if(title===null){
      
      return;
    }
    else if(title.trim()==''){
      alert(this.text.text_plan.error);
      return;
    }else{
       
      for(var i=0;i<this.groupePlanning.length;i++){
        if(title==this.groupePlanning[i].designation){
          this.popupService.alertOpen(this.text.text_plan.alert_nom_exi);
          return;
        }
      }
     
      let re = /[\\]/gi;
      let re2 = /[/]/gi;
      title=title.replace(re,"-");
      title=title.replace(re2,"-");
      this.ajouteInfo(title);     
        
    }
   }

  supprimer(){
    for(var i=0;i<this.control.length;i++){
      if(this.control[i]==true){
       var conf= confirm(this.text.text_plan.supprimePlanning);
        if(conf){
        this.delInfo(this.groupePlanning[i].id);
        return;
        }else{
          return;
        }
      }
    }
  }
  modify(){
    for(var i=0;i<this.control.length;i++){
      if(this.control[i]==true){
        var title = prompt(this.text.text_plan.changePlanning,this.groupePlanning[i].designation);
        if(title.trim()==''){
         alert(this.text.text_plan.error);
        } else if(title!=null)
        {
          let re = /[\\]/gi;
          let re2 = /[/]/gi;
          title=title.replace(re,"-");
          title=title.replace(re2,"-");
         this.changeInfo(this.groupePlanning[i].id,title);
        this.getInfo();
        }else{
          return;
        }
      }
    }
  }

  search(){
    this.reset();
    this.search_control=true;
  }

  ordre(e){
    this.reset();
    if(e.target.className.indexOf("or_name")!=-1){
      this.or_control=[false,true];
      for(var i=0;i<this.groupePlanning.length;i++){
        for(var j=i+1;j<this.groupePlanning.length;j++){
          if(this.groupePlanning[i].designation.toLowerCase()>this.groupePlanning[j].designation.toLowerCase()){
            var g:any = this.groupePlanning[j];
            this.groupePlanning[j]=this.groupePlanning[i];
            this.groupePlanning[i]=g;
          }
        }
      }
    }else if(e.target.className.indexOf("or_id")!=-1){
      this.or_control=[true,false];
      for(var i=0;i<this.groupePlanning.length;i++){
        for(var j=i+1;j<this.groupePlanning.length;j++){
          if(parseInt(this.groupePlanning[i].id)>parseInt(this.groupePlanning[j].id)){
            var g:any = this.groupePlanning[j];
            this.groupePlanning[j]=this.groupePlanning[i];
            this.groupePlanning[i]=g;
          }
        }
      }
    }
  
  
  
  
  
  }



  getInfo(){
    this.idCompteClient = localStorage.getItem("cle_axe_client_rattachement");
    var xcodegroupe = localStorage.getItem("code_rh_groupe");
  
    let tsql ="exec sp_festiming_list_PARAM_JOURNEE_TYPE @cle_axe_client_rattachement="+this.idCompteClient + ", @code_rh_groupe='" + xcodegroupe + "'"; 

    this.data.SqlSelectToJson(tsql).subscribe // création d'un observateur dynamique sur l'observable -> data.SqlSubmit(..)
      (
        (data:any) =>  {
          
            this.groupePlanning=data;
            if(this.groupePlanning[0].nbligne==0){
              console.log(this.groupePlanning);
              this.groupePlanning=[];
              return;
            }else{
              
            }
          
                                                                                      
        },
    
        (error) =>       {
              // console.log("__________Observable SqlSubmit a renvoy?une erreur : "+ error);                                             
        alert(error);
        },                                       
                             // Evenement de fin d'exécution en provenance de l'observable quand exécution normal!  bloc de codes (data) exécut?avant, puis blocs de code ()
        () =>         {
          for(var i=0;i<this.groupePlanning.length;i++){
            this.control[i]=false;
            }                                              // on passe uniquement ici après que l'exécution soit normale , donc événement (data)      //  console.log("__________Observable SqlSubmit a renvoy?un événement de fin d'exécution.");
        } 
               // OBSERVATEUR FIN .......................................................................................................

    );
  }
 
  ajouteInfo(title:any){
    this.idCompteClient = localStorage.getItem("cle_axe_client_rattachement");
    var xcodegroupe = localStorage.getItem("code_rh_groupe");

    let tsql ="exec sp_festiming_list_PARAM_JOURNEE_TYPE_ADD @cle_axe_client_rattachement={idCompteClient} , @designation = '{designation}' , @code_rh_groupe='{code_rh_groupe}'"
    tsql = tsql.replace("{idCompteClient}",this.idCompteClient);
    tsql = tsql.replace("{designation}",title);
    tsql = tsql.replace("{code_rh_groupe}",xcodegroupe);

      this.data.SqlSelectToJson(tsql).subscribe // création d'un observateur dynamique sur l'observable -> data.SqlSubmit(..)
      (
        (data:any) =>  {},
        (error) =>       {
           console.log("__________Observable SqlSubmit a renvoy?une erreur : "+ error);                                             
          alert(error);
           },
        () =>{
          this.getInfo()
        } 
      );
  }

  delInfo(id){  
    this.idCompteClient = localStorage.getItem("cle_axe_client_rattachement");

    let tsql ="exec sp_festiming_list_PARAM_JOURNEE_TYPE_DELETE @cle_axe_client_rattachement={idCompteClient} , @id_to_delete={id}"
    tsql = tsql.replace("{idCompteClient}",this.idCompteClient);
    tsql = tsql.replace("{id}",id);

    // exec sp_festiming_list_PARAM_JOURNEE_TYPE_DELETE @cle_axe_client_rattachement=13 , @id_to_delete=41    
    this.data.SqlSelectToJson(tsql).subscribe 
      (
        (data:any) =>  {
          if(data[0].ReturnCode=='KO'){
            alert(data[0].ReturnMessage);
          }

        },
        (error) => {
          console.log("__________Observable SqlSubmit a renvoy?une erreur : "+ error);                                             
          alert(error);
          },
          ()=>{
            this.getInfo()
          } 
      );
  }
  changeInfo(id,title){
    
    this.idCompteClient = localStorage.getItem("cle_axe_client_rattachement");

    let tsql ="exec sp_festiming_list_PARAM_JOURNEE_TYPE_UPDATE @cle_axe_client_rattachement={idCompteClient} , @id_to_update={id} , @designation_update='{designation}'"
    tsql = tsql.replace("{idCompteClient}",this.idCompteClient);  
    tsql = tsql.replace("{id}",id);
    tsql = tsql.replace("{designation}",title);
    // exec sp_festiming_list_PARAM_JOURNEE_TYPE_UPDATE @cle_axe_client_rattachement=13 ,  @id_to_update=77, @designation_update='MaGaSiNIERS DEPOT'
    this.data.SqlSelectToJson(tsql).subscribe // cr閍tion d'un observateur dynamique sur l'observable -> data.SqlSubmit(..)
      (
        (data:any) =>  {},
    // Evenement d'erreur d'ex閏ution en provenance de l'observable
        (error) =>  {console.log(error);},                        
          () => { }
      );
  }
  trackByIndex(index,item){
    return index;
  }
}

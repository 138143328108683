import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './ihm/admin/login/login.component';
import { WorkerComponent } from './ihm/tools/worker/worker.component';
import { GethttptestComponent } from './tools/gethttptest/gethttptest.component';
import { DesignPlanningComponent } from './ihm/admin/design-planning/design-planning.component';
import { HomeComponent } from './ihm/admin/home/home.component';
import { ModifierPlanningComponent } from './ihm/admin/design-planning/modifier-planning/modifier-planning.component';
import { ExtUrlComponent } from './ihm/admin/ext-url/ext-url.component';
import { PointerComponent } from './ihm/admin/pointer/pointer.component';
import { EditionComponent } from './ihm/admin/pointer/edition/edition.component';


// const routes: Routes = [];


// Création de la route à associer à un composant pour son comporter en gros comme une URL au format Json, separamtre comme indiqué ci-dessous
const routes: Routes = 
[
  { path: 'admin/login', component: LoginComponent }, // http://localhost:4200/admin/login
  { path: 'planning', component: DesignPlanningComponent,
    children:[
      {path:'modify',component:ModifierPlanningComponent,runGuardsAndResolvers: 'paramsOrQueryParamsChange'}
    ]
 },                                                          // Quand routage sur AppComponent (Startup) le navigateur efface le chemin de routage dans la barre du navigateur
  { path: 'dashboard', component: WorkerComponent },
  { path: 'httpget', component: GethttptestComponent },
  { path: 'login', component:LoginComponent },
  { path:  'extUrl/:adress', component:ExtUrlComponent},
  { path: 'pointer', component:PointerComponent,
    children:[
      {path:'edition',component:EditionComponent,runGuardsAndResolvers: 'always'}
    ]
 },
  { path: '**', component:HomeComponent},


];



@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation:'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

export class AppData {

   
    public tsql: string;
    public fluxretour:string;
    public coderet:string;
    public textret:string;
    public dataret:string;

    constructor () 
    {
      this.init();
    }
  

    init() 
    {
        this.tsql = "";
        this.fluxretour = "";
        this.coderet = "";
        this.textret = "";
        this.dataret = "";
    }


}

  
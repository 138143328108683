import { Component, OnInit } from '@angular/core';
import { PopupService } from 'src/app/services/popup.service';
import { debounce } from 'rxjs/operators';
import { Observable, observable } from 'rxjs';


@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class PopupComponent implements OnInit {
  public test =1 ;
  constructor(
    public popupService:PopupService
  ) { }
  
    confirmeResult(result){
      this.popupService.confirmResult = new Observable((res)=>{
        res.next(result);
      })
    }
   
  ngOnInit() {
    
    
  }

}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderComponent } from './ihm/master/header/header.component';
import { FooterComponent } from './ihm/master/footer/footer.component';
import { LoginComponent } from './ihm/admin/login/login.component';
import { DashboardComponent } from './ihm/admin/dashboard/dashboard.component';
import { WorkerComponent } from './ihm/tools/worker/worker.component';
import { GethttptestComponent } from './tools/gethttptest/gethttptest.component';

// Other imports
import { HttpClientModule } from '@angular/common/http';
import { DesignPlanningComponent } from './ihm/admin/design-planning/design-planning.component';
import { HomeComponent } from './ihm/admin/home/home.component';
import { FilterPipe } from './pipe/filter.pipe';
import { ReactiveFormsModule,FormsModule } from '@angular/forms';
import { ModifierPlanningComponent } from './ihm/admin/design-planning/modifier-planning/modifier-planning.component';
import { ExtUrlComponent } from './ihm/admin/ext-url/ext-url.component';
import { PointerComponent } from './ihm/admin/pointer/pointer.component';

//
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { PopupComponent } from './ihm/tools/popup/popup.component';
import { PopupService } from './services/popup.service';
import { EditionComponent } from './ihm/admin/pointer/edition/edition.component';

// cette ligne permet d'actualiser le site sans bug de page introuvable - 23Sep2019 - LZ 



@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    LoginComponent,
    DashboardComponent,
    WorkerComponent,
    GethttptestComponent,
    DesignPlanningComponent,
    HomeComponent,
    FilterPipe,
    ModifierPlanningComponent,
    ExtUrlComponent,
    PointerComponent,
    PopupComponent,
    EditionComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

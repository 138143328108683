import { Component, OnInit, Input, Output,  EventEmitter } from '@angular/core';
import { DataService } from 'src/app/data.service';
import { Router } from '@angular/router';
import { TextService } from 'src/app/services/text.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public user_login:string;
  public password_login:string;
  public login_act:string=localStorage.getItem('loggedIn');
  private fail_time:any;
  public oublier;

  constructor(
    private data: DataService,
    private route:Router,
    public text:TextService
  ) { }

  ngOnInit() {
     this.login=0;
     if(this.login_act=='OK'){
       this.login=1;
       this.loginChange.emit(this.login);
     }
     this.fail_time=localStorage.getItem("fail_time")?localStorage.getItem("fail_time"):0;
     this.oublier=0;
  }
  @Input() login;
  @Output() loginChange = new EventEmitter();
  func_login(){
    
    
    /*
    let tsql ="exec sp_festiming_login @compte_festime_login='{ihm_festime_login}', @compte_festime_password='{ihm_festime_password}'";
    tsql = tsql.replace("{ihm_festime_login}",this.user_login);
    tsql = tsql.replace("{ihm_festime_password}",this.password_login);
    //exec sp_festiming_login @compte_festime_login='mabouna2', @compte_festime_password='1234' 
    */

   let tsql ="exec sp_festiming_login @compte_festime_login='{ihm_festime_login}', @compte_festime_password='{ihm_festime_password}'";
   tsql = tsql.replace("{ihm_festime_login}",this.data.TransformParametreSpeciaux(this.user_login));
   tsql = tsql.replace("{ihm_festime_password}",this.data.TransformParametreSpeciaux(this.password_login));
   //alert(tsql);


    this.data.SqlSelectToJson(tsql).subscribe 
      (
        // OBSERVATEUR DEBUT .......................................................................................................
        // Evenement de Fin de d'exution correcte en provenance de l'observable
        (data:any) =>  {
         
          let mydata = data;



     if ( mydata[0].ReturnCode == 'OK' ) // Logged OK
     {
        localStorage.setItem('loggedIn','OK');
		    localStorage.setItem("login_utilisateur",mydata[0].login_utilisateur);
        localStorage.setItem("libelle_utilisateur",mydata[0].libelle_utilisateur);
        localStorage.setItem("cle_axe_client_rattachement",mydata[0].cle_axe_client_rattachement);
        localStorage.setItem("libelle_axe_client",mydata[0].libelle_axe_client);
        localStorage.setItem("code_profil",mydata[0].code_profil);
        localStorage.setItem("code_rh_groupe",mydata[0].code_rh_groupe);
        localStorage.setItem("actif_utilisateur",mydata[0].actif_utilisateur);
        // localStorage.setItem("email",mydata[0].email);
        this.login=1;
        this.loginChange.emit(this.login);
        localStorage.setItem("fail_time",'0');
        localStorage.setItem("date",new Date().getTime().toString());
		  }
		  else // Logged KO
		  {
        localStorage.setItem('loggedIn','KO');
        localStorage.setItem("login_utilisateur", "");
        localStorage.setItem("libelle_utilisateur", "");
        localStorage.setItem("cle_axe_client_rattachement", "0");
        localStorage.setItem("libelle_axe_client", "");
        localStorage.setItem("code_profil","");
        localStorage.setItem("code_rh_groupe", "");
        localStorage.setItem("actif_utilisateur", "");
        localStorage.setItem("fail_time",(parseInt(this.fail_time)+1).toString());
        this.fail_time=localStorage.getItem("fail_time");
        alert(this.fail_time+this.text.login.login_fail);
        return
		  }                                                                  
    },


    // Evenement d'erreur d'exécution en provenance de l'observable
    (error) =>       {                                       
           alert('error');          
		// Logged Error ( error logged on computer system )
		       localStorage.setItem('loggedIn','ERROR');
		       localStorage.setItem("login_utilisateur", "");
           localStorage.setItem("libelle_utilisateur", "");
        	 localStorage.setItem("cle_axe_client_rattachement", "0");
        	 localStorage.setItem("libelle_axe_client", "");
        	 localStorage.setItem("code_profil","");
        	 localStorage.setItem("code_rh_groupe", "");
           localStorage.setItem("actif_utilisateur", "");
           },                          
          () =>         {} 
           // OBSERVATEUR FIN .......................................................................................................
            );
   }
 

   forget(){
     this.route.navigate(['/']);
     console.log('run');  
   }

   

}

import { Injectable } from '@angular/core';
import { HttpClient , HttpEvent, HttpInterceptor, HttpHandler, HttpRequest} from '@angular/common/http';  // Import it up here
import { timeout, map, retry , catchError , retryWhen, delay, mergeMap } from 'rxjs/operators';
import { stringify } from 'querystring';
import { Observable, of } from 'rxjs';


// |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
// Récupération des paramètres de configuration dans le module, selon la position relative du module
//*** Toute modification du fichier assets/config_festiming.json , implique une recompilation du code pour la prise en compte des paramètres ****
import myconfig from "../assets/config_festiming.json";
/*
{
  "VERSION": "MyTiming 1.3",
  "URL_WEBAPI_SERVER_TEST":  "http://siege.abiova.net/bkt.aspx?fonction={fonction}&param1={tsql}",
  "URL_WEBAPI_SERVER_PROD":  "https://backend.mytiming.fr/bkt.aspx?fonction={fonction}&param1={tsql}",
  "URL_SERVER_BACKEND_TEST": "http://siege.abiova.net",
  "URL_SERVER_BACKEND_PROD": "https://backend.mytiming.fr",
  "URL_SERVER_FESTIME":      "https://cloud.festime.fr",
  "MODE_EXECUTION":          "TEST",
  "ACTIVER_LIEN_DEBUG":      true
}
*/
// |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||


// ---------------------------------
// Genesis :
// ng generate service data
// --------------------------------


@Injectable({
  providedIn: 'root'
})
export class DataService implements HttpInterceptor
{
      
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> 
  {
    return next.handle(req).pipe(timeout(180000) , Observable.throw );
    // 30000 (30s) would be the global default for example
  }


      // SERVEUR DE TEST
      URL_SERVER_TEST:string =  myconfig.URL_WEBAPI_SERVER_TEST; //"http://siege.abiova.net/bkt.aspx?fonction={fonction}&param1={tsql}";
      URL_SERVER_ROOT_TEST:string = myconfig.URL_SERVER_BACKEND_TEST; // "http://siege.abiova.net"

      // SERVEUR DE PRODUCTION
      URL_SERVER_PROD:string =  myconfig.URL_WEBAPI_SERVER_PROD; //"http://backend.mytiming.fr/bkt.aspx?fonction={fonction}&param1={tsql}";
      URL_SERVER_ROOT_PROD:string = myconfig.URL_SERVER_BACKEND_PROD; // "https://backend.mytiming.fr"

      URL_SERVER:string = "";   
      URL_SERVER_ROOT:string = "";

      SSRS_REPORT:string = "";

      // -------------------
      MODE_EXECUTION = myconfig.MODE_EXECUTION.toUpperCase(); // "PROD" ou "TEST"
      // -------------------
      
      
      constructor(private http: HttpClient) 
        { 

                        switch (this.MODE_EXECUTION) 
                        {
                          case "TEST": 
                                    this.URL_SERVER = this.URL_SERVER_TEST;
                                    this.URL_SERVER_ROOT= this.URL_SERVER_ROOT_TEST;
                                    this.SSRS_REPORT = "dev_rpt_myt_pt_utilisateur";
                              break;


                          case "PROD": 
                                    this.URL_SERVER = this.URL_SERVER_PROD;
                                    this.URL_SERVER_ROOT= this.URL_SERVER_ROOT_PROD;
                                    this.SSRS_REPORT = "rpt_myt_pt_utilisateur";
                              break;
                        

                          default:
                            this.URL_SERVER = this.URL_SERVER_TEST;
                            this.URL_SERVER_ROOT= this.URL_SERVER_ROOT_TEST;
                            this.SSRS_REPORT = "";
                            break;
                      }
        }


        // ---------------------
        // Fonctions Observables
        // ---------------------


           /*

                    -- TEST
                -equivalent dans SQL :
                update [Z_TEST] set [the_cost] = ((([the_cost]-77.77) + 1)*3)/1 , [the_name] = [the_name] + '# $~|&"  ''<>*;:% ''ZUT'' ''OK''' where cle_Z_TEST=5

                -equivalent dans web-api
                http://siege.abiova.net/bkt.aspx?fonction=tsql_execute&param1=update [Z_TEST] set [the_cost] = ((([the_cost]-77.77) {plus} 1)*3)/1 , [the_name] = [the_name] {plus} '{diese} $~|{et}"  ''<>*;:% ''ZUT'' ''OK''' where cle_Z_TEST=5

                En version WEB-API :

                Changer  "+"  par  {plus}
                Changer  "&"  par  {et}
                Changer  "#"  par  {diese}
                

                update [Z_TEST] set [the_name] = '-' where cle_Z_TEST=5

                type WEB-API :

                -- Exec

                http://siege.abiova.net/bkt.aspx?fonction=tsql_execute&param1=



                -- Selection :

                http://siege.abiova.net/bkt.aspx?fonction=tsql_select_json&param1=

                http://siege.abiova.net/bkt.aspx?fonction=tsql_select_csv&param1=


              */


        
        SqlSelectToJson(txsql:string) 
        {
          let tsql: string = this.URL_SERVER;
          tsql = tsql.replace("{fonction}","tsql_select_json");
          tsql = tsql.replace("{tsql}",this.TransformTsqlToHttp(txsql) );
          //tsql = this.TransformTsqlToHttp(tsql);

          // return this.http.get(tsql);


        console.log("SqlSelectToJson : "+tsql);
         
      return this.http.get(tsql);


      /*
      return this.http.get(tsql)
      .pipe(
         timeout(2000*10*10),
         catchError(e => {
           alert('timeout expired');
           
           // do something on a timeout
           return of(null);
         })
       )
      */



      //return this.http.get(tsql).pipe(timeout(180000)); // Renvoie un objet de type Observable (format de retour JSON)

      /*
         return this.http.get(tsql).pipe(timeout(60000)); // Renvoie un objet de type Observable (format de retour JSON)
         return this.http.get(tsql).pipe(
                                            timeout(900000),
                                            retry(0),                                          
                                            map
                                              (
                                                response => {
                                                              return response;
                                                            }
                                              ),

                                              catchError((err, caught) => caught)


                                         );

         */
        

         /*
         
        return this.http.get(tsql).pipe(
                                          timeout(60000),
                                          

                                          retryWhen 
                                                     (
                                                        (errors: Observable<any>) => errors.pipe
                                                                                    (
                                                                                       delay(30000),                                                                                       
                                                                                       
                                                                                     
                                                                                    
                                                                                    )

                                                     )

                                      );


*/

        }



        SqlSelectToCsv(txsql:string) 
        {
          let tsql: string = this.URL_SERVER;
          tsql = tsql.replace("{fonction}","tsql_select_csv");
          tsql = tsql.replace("{tsql}",this.TransformTsqlToHttp(txsql) );
          //tsql = this.TransformTsqlToHttp(tsql);
          
          console.log("SqlSelectToCsv : "+tsql);

         
          return this.http.get(tsql, {responseType: 'text'}); // Renvoie un objet de type Observable (format de retour de flux texte csv ..;..;..;\n (saut de ligne) )

        }



        SqlSubmit(txsql:string)  // http://siege.abiova.net/bkt.aspx?fonction={fonction}&param1={tsql}
        {
          let tsql: string = this.URL_SERVER;
          tsql = tsql.replace("{fonction}","tsql_execute");
          tsql = tsql.replace("{tsql}",this.TransformTsqlToHttp(txsql) );
          //tsql = this.TransformTsqlToHttp(tsql);


          console.log("SqlSubmit : "+tsql);
                    
          
          return this.http.get(tsql, {responseType: 'text'}); // Renvoie un objet de type Observable (format de retour en flux normal avec en général 3 zones séparés par ";" mais parfois un flux sui generis.
                                                              
        }



        TransformTsqlToHttp(txsql:string)
        {
          let httpsql:string = txsql;

          /*
                En version WEB-API :

                Changer  "+"  par  {plus}
                Changer  "&"  par  {et}
                Changer  "#"  par  {diese}
                
          */
          
         
         //------------------------------------ Flux WEB
          let re= /[+]/gi; 
          httpsql = httpsql.replace(re,"{plus}");     // remplacer tous les caractères "+" par {plus}

          re = /[&]/gi;
          httpsql = httpsql.replace(re,"{et}");       // remplacer tous les caractères "&" par {et}

          re = /[#]/gi;
          httpsql = httpsql.replace(re,"{diese}");    // remplacer tous les caractères "#" par {diese}
          //------------------------------------ Flux WEB


          return httpsql;
        }


        


        TransformParametreSpeciaux(x_parametre:string)
        {
          let parametre:string = x_parametre;

          /*
                $ -> '_(d)_'
                
          */
          

         // ***********************************************
         // Transformation caracteres speciaux ANGULAR qui pose Pb a la compilation 
         let re= /[$]/gi; 
         parametre = parametre.replace(re,"_(d)_");     // remplacer tous les caractères "$" par _(d)_
         // ***********************************************

       


          return parametre;
        }


        // URL Externe Reporting
        GetUrlReporting(cle_axe_client_rattachement:string , cle_uid_axe_rh_referentiel:string ,  date_deb_jjmmaaaa:string, date_fin_jjmmaaaa:string)
        {
         let cle_axe_rh_referentiel:string=""; // cle_uid_axe_rh_referentiel = "311/3671299892" , puis cle_axe_rh_referentiel = "311" 
         let new_addr:string = "";

          // https://docs.microsoft.com/fr-fr/sql/reporting-services/url-access-parameter-reference?view=sql-server-ver15
          let TplUrlReport:string="{URL_SERVER}/ReportServer/Pages/ReportViewer.aspx?%2fRpt_Myt_Pointage%2f{SSRS_REPORT}&rs:Command=Render&cle_axe_client_rattachement={cle_axe_client_rattachement}&cle_axe_rh_referentiel={cle_axe_rh_referentiel}&date_deb_jjmmaaaa={date_deb_jjmmaaaa}&date_fin_jjmmaaaa={date_fin_jjmmaaaa}";
        
          // Extraire clef utilisateur de cle_uid_axe_rh_referentiel en paramètre , exemple : 311/3671299892...dans ce cas on doit extraire 311
          var posit = cle_uid_axe_rh_referentiel.indexOf('/');
          if (posit >=0 ) // trouvé caractère "/"
          {
            cle_axe_rh_referentiel = cle_uid_axe_rh_referentiel.substring(0,posit);
          }
            else  // non trouvé caractère "/"
            {
              cle_axe_rh_referentiel = cle_uid_axe_rh_referentiel;
            }


          //.......................................... DEBUT - PARAMETRES DE SUBSTITUTION 
          // this.URL_SERVER  
          // {cle_axe_client_rattachement}
          // {cle_axe_rh_referentiel}
          // {date_deb_jjmmaaaa}
          // {date_fin_jjmmaaaa}
          //.......................................... FIN  - PARAMETRES SUBSTITUTION

          new_addr = TplUrlReport;
          new_addr = new_addr.replace("{URL_SERVER}",this.URL_SERVER_ROOT);
          new_addr = new_addr.replace("{SSRS_REPORT}",this.SSRS_REPORT);
          new_addr = new_addr.replace("{cle_axe_client_rattachement}",cle_axe_client_rattachement);
          new_addr = new_addr.replace("{cle_axe_rh_referentiel}",cle_axe_rh_referentiel);
          new_addr = new_addr.replace("{date_deb_jjmmaaaa}",date_deb_jjmmaaaa);
          new_addr = new_addr.replace("{date_fin_jjmmaaaa}",date_fin_jjmmaaaa);

          
          return new_addr;

        }














}

import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser'; // Trusted link ( Autorisation d'injection URL )


// Access relatif au modèle
import { standardizeConfig } from '@angular/router/src/config';
import { ConditionalExpr } from '@angular/compiler';
import { Alert } from 'selenium-webdriver';
import { Stand } from 'src/app/models/Stand.model';
import * as $ from "jquery";


@Component({
  selector: 'app-worker',
  templateUrl: './worker.component.html',
  styleUrls: ['./worker.component.css']
})




export class WorkerComponent implements OnInit
{
  // Class qui est intimement lié avec le composant worker , cad avec le contenu html (vue) -> worker.component.html
    
  // ---------------------------
  //------------------
  // variable globale de la Classe
  public properties: Stand[] = []; // definition d'une variable properties de type tableau d'objet Property (voir modèle de données Property) initialisé à tableau vide ([])
 
  
  constructor(private sanitizer: DomSanitizer)
   { 
    this.AlimenterStands();
    //this.updateVideoUrl("");  
   }


// Fonction native réservée Angular7 - exécuté au chargement du composant , ou initialisation du composant.
  ngOnInit() 
  {
    this.loadScript0(); // charger file 1 Javascript natif coté page
    this.loadScript();  // charger file 2 Javascript natif coté page
  }
  

 AlimenterStands()
 {
  
// Stand 01
   let stand1: Stand  = new  Stand(); // variable stand0 de type Property
   stand1.SetUrlStand("1","https://www.facebook.com/adm97160/posts/1998026067169255");
   
// Stand 02
   let stand2: Stand  = new  Stand(); // variable stand0 de type Property
   stand2.SetUrlStand("2","https://www.facebook.com/adm97160/posts/1998026487169213");
 
// Stand 03   
   // Maison du Crabe
   let stand3: Stand  = new  Stand(); // variable stand0 de type Property
   stand3.SetUrlStand("3","https://www.facebook.com/adm97160/posts/1998026650502530");

// Stand 04
let stand4: Stand  = new  Stand(); // variable stand0 de type Property
stand4.SetUrlStand("4","https://www.facebook.com/adm97160/posts/1998026873835841");

// Stand 05
let stand5: Stand  = new  Stand(); // variable stand0 de type Property
stand5.SetUrlStand("5","https://www.facebook.com/adm97160/posts/1998029007168961");


// Stand 06
let stand6: Stand  = new  Stand(); // variable stand0 de type Property
stand6.SetUrlStand("6","https://www.facebook.com/adm97160/posts/1998029277168934");

// Stand 07
let stand7: Stand  = new  Stand(); // variable stand0 de type Property
stand7.SetUrlStand("7","https://www.facebook.com/adm97160/posts/1998029857168876");


// Stand 08
let stand8: Stand  = new  Stand(); // variable stand0 de type Property
stand8.SetUrlStand("8","https://www.facebook.com/adm97160/posts/1998030083835520");

// Stand 09
let stand9: Stand  = new  Stand(); // variable stand0 de type Property
stand9.SetUrlStand("9","https://www.facebook.com/adm97160/posts/1998030377168824");

// Stand 10
let stand10: Stand  = new  Stand(); // variable stand0 de type Property
stand10.SetUrlStand("10","https://www.facebook.com/adm97160/posts/1998030580502137");

// Stand 11
let stand11: Stand  = new  Stand(); // variable stand0 de type Property
stand11.SetUrlStand("11","https://www.facebook.com/adm97160/posts/1998030803835448");

// Stand 12
let stand12: Stand  = new  Stand(); // variable stand0 de type Property
stand12.SetUrlStand("12","https://www.facebook.com/adm97160/posts/1998030973835431");

// Stand 13
let stand13: Stand  = new  Stand(); // variable stand0 de type Property
stand13.SetUrlStand("13","https://www.facebook.com/adm97160/posts/1998031313835397");

// Stand 14
let stand14: Stand  = new  Stand(); // variable stand0 de type Property
stand14.SetUrlStand("14","https://www.facebook.com/adm97160/posts/1998031483835380");

// Stand 15
let stand15: Stand  = new  Stand(); // variable stand0 de type Property
stand15.SetUrlStand("15","https://www.facebook.com/adm97160/posts/1998031650502030");


// Stand 16
let stand16: Stand  = new  Stand(); // variable stand0 de type Property
stand16.SetUrlStand("16","https://www.facebook.com/adm97160/posts/1998031813835347");


// Stand 17
let stand17: Stand  = new  Stand(); // variable stand0 de type Property
stand17.SetUrlStand("17","https://www.facebook.com/adm97160/posts/1998032020501993");


// Stand 18
let stand18: Stand  = new  Stand(); // variable stand0 de type Property
stand18.SetUrlStand("18","https://www.facebook.com/adm97160/posts/1998032180501977");

// Stand 19
let stand19: Stand  = new  Stand(); // variable stand0 de type Property
stand19.SetUrlStand("19","https://www.facebook.com/adm97160/posts/1998032320501963");

// Stand 20
let stand20: Stand  = new  Stand(); // variable stand0 de type Property
stand20.SetUrlStand("20","https://www.facebook.com/adm97160/posts/1998032457168616");



  // alert("coucou 2");
  
  // Début - Stand 00  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  
  this.properties.push(stand1); // ajouter l'objet stand0 dans le tableau de type properties ( toujours à la fin d'un tableau )
  this.properties.push(stand2);
  this.properties.push(stand3);
  this.properties.push(stand4);
  this.properties.push(stand5);
  this.properties.push(stand6); // ajouter l'objet stand0 dans le tableau de type properties ( toujours à la fin d'un tableau )
  this.properties.push(stand7);
  this.properties.push(stand8);
  this.properties.push(stand9);
  this.properties.push(stand10);
  this.properties.push(stand11); // ajouter l'objet stand0 dans le tableau de type properties ( toujours à la fin d'un tableau )
  this.properties.push(stand12);
  this.properties.push(stand13);
  this.properties.push(stand14);
  this.properties.push(stand15);
  this.properties.push(stand16); // ajouter l'objet stand0 dans le tableau de type properties ( toujours à la fin d'un tableau )
  this.properties.push(stand17);
  this.properties.push(stand18);
  this.properties.push(stand19);
  this.properties.push(stand20);
  
  // this.properties.push(stand0,stand1); // ajouter l'objet stand0 dans le tableau de type properties ( toujours à la fin d'un tableau )


   // Fin   - Stand 00 ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

   // alert("coucou 3");


  // Début - Stand 01  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

   // Fin   - Stand 01 ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 
   // Tester sortie.
   console.log("Résultat :");
   console.log(this.properties); // affichage du nombre d'objet dans la liste
   console.log(this.properties[0].av_titre_big); // affichage du nombre d'objet dans la liste
   console.log("(fin)");
  }



  // https://angular.io/guide/security#xss
  ConvertUrlToSafeUrl(url: string)
  {
    // Appending an ID to a YouTube URL is safe.
    // Always make sure to construct SafeValue objects as
    // close as possible to the input data so
    // that it's easier to check if the value is safe.
    //this.dangerousVideoUrl = 'https://www.youtube.com/embed/' + id;

    // this.trustedDashboardUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    // return this.trustedDashboardUrl;

    let trustedDashboardUrl: SafeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    return trustedDashboardUrl;
  }


  
  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ 
  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Chargement de fichier JavaScript uniquement pour le composant courant
  public loadScript0() {
    let body = <HTMLDivElement> document.body;
    let script = document.createElement('script');
    script.innerHTML = '';
    script.src = '/assets/js/jquery-1.10.2.js';
    script.async = true;
    script.defer = true;
    body.appendChild(script);
}


public loadScript() {
      let body = <HTMLDivElement> document.body;
      let script = document.createElement('script');
      script.innerHTML = '';
      script.src = '/assets/js/worker.js';
      script.async = true;
      script.defer = true;
      body.appendChild(script);
}
  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ 
    // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ 


}

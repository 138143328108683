import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-ext-url',
  templateUrl: './ext-url.component.html',
  styleUrls: ['./ext-url.component.scss']
})
export class ExtUrlComponent implements OnInit {
  public adress:any;
  constructor(
    private route:ActivatedRoute,
    public sanitizer:DomSanitizer
  ) { }
  
  ngOnInit() {
    this.route.params.subscribe((data)=>{
      this.adress = this.sanitizer.bypassSecurityTrustResourceUrl(data.adress);
    })
  }

}
